<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="tabs-sticky marginBottom30" ref="tabsSticky">
            <nav class="links margin0">
                <ul>
                    <li v-for="tab in tabs" :key="tab" :class="{ active: currentTab === tab }" @click="currentTab = tab">
                        <a class="nav-link" data-toggle="tab" :href="`#${tab}`" :aria-controls="tab" :aria-selected="currentTab == tab">
                            <template v-if="tab == 'module'"> Modules & Activities </template>
                            <template v-else-if="tab == 'student'"> Students </template>
                            <template v-else-if="tab == 'downloadables'"> Grades </template>
                            <template v-else-if="tab == 'overview'"> Settings </template>
                            <template v-else-if="tab == 'collaborators'"> Collaborators </template>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="pageTitle">
            <template v-if="currentTab == 'module'">
                <h1>Modules & Activities</h1>
                <p class="col-md-6">
                    <a href="https://help.intedashboard.com/how-do-i-organize-my-modules" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        Organize your course lesson plans into modules
                    </a>
                    and
                    <a href="https://help.intedashboard.com/how-do-i-create-an-activity" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        create activities
                    </a>
                    within the modules. You can
                    <a href="https://help.intedashboard.com/how-do-i-create-a-module" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        create as many modules
                    </a>
                    and activities you need.
                </p>
            </template>
            <template v-else-if="currentTab == 'student'">
                <template v-if="!updateManageValue">
                    <h1>Students</h1>
                    <p class="col-md-6">
                        <a href="https://help.intedashboard.com/how-do-i-enroll-learners-to-a-course" target="newTab">
                            <i class="fas fa-info-circle" aria-hidden="true" />
                            Enroll
                        </a>
                        and manage students here. You can create "<a
                            href="https://help.intedashboard.com/how-do-i-run-activities-across-multiple-sections"
                            target="newTab"
                        >
                            <i class="fas fa-info-circle" aria-hidden="true" />
                            sections</a
                        >" to group students who are going to take this course together.
                    </p>
                </template>
                <template v-else>
                    <div class="flex">
                        <h1>Student Accommodations</h1>
                        <i class="fas fa-universal-access color-purple fs-24px marginLeft8" aria-hidden="true" />
                    </div>
                    <p class="col-md-6">
                        Manage default accommodations settings here.
                        <br />
                        When you create a timed individual activity, students with these accommodations will appear on the Publish settings.
                    </p>
                </template>
            </template>

            <template v-else-if="currentTab == 'downloadables'">
                <h1>Grades</h1>
                <p class="col-md-6">
                    Download any triggered activity-level or course-level grades here.
                    <a href="https://help.intedashboard.com/how-do-i-download-my-activitys-grades" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        Trigger an activity-level grades export
                    </a>
                    or
                    <a href="https://help.intedashboard.com/where-can-i-download-the-full-course-grades" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        Trigger the course-level grades export </a
                    >.
                </p>
            </template>

            <template v-else-if="currentTab == 'overview'">
                <h1>Settings</h1>
                <p class="col-md-6">Manage the Course Details, and Course Enrollment Settings here.</p>
            </template>

            <template v-else-if="currentTab == 'collaborators'">
                <h1>Collaborators</h1>
                <p class="col-md-6">
                    Manage all collaborators on your courses from one place. Grant or deny access requests,
                    <a href="https://help.intedashboard.com/how-to-switch-course-collaborator-role" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        switch collaborator roles</a
                    >,
                    <a href="https://help.intedashboard.com/how-to-transfer-course-ownership" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        transfer course ownership</a
                    >, and
                    <a href="https://help.intedashboard.com/how-to-add-collaborator" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        add new collaborators
                    </a>
                    with ease. View a detailed permission matrix to understand access levels and maintain full control over your course collaborations.
                </p>
            </template>
        </div>

        <div>
            <template v-if="currentTab == 'module'">
                <kr-panel v-if="$can('view_modules_and_activities', 'Course-' + course.uuid)" :with-footer="false" :with-title="false" class="new-panel">
                    <template #content>
                        <div class="flex">
                            <aside v-if="sectionList.length > 1" class="x_side_bar" aria-label="Sections sidebar">
                                <div class="list-group">
                                    <h2 class="fs-18px margin0 marginBottom10">Sections</h2>
                                    <ul class="padding0">
                                        <template v-for="(model, idx) in sectionList" :key="model.uuid + '_section_list'">
                                            <li class="listStyleNone">
                                                <a
                                                    href="#"
                                                    class="list-group-item list-group-item-action"
                                                    :class="{
                                                        active: model.uuid == query_fields.sectionUuid,
                                                    }"
                                                    @click="
                                                        store.setCurrentSection({
                                                            courseUuid: course.uuid,
                                                            sectionUuid: model.uuid,
                                                        });
                                                        query_fields.sectionUuid = model.uuid;
                                                        searchModule();
                                                    "
                                                >
                                                    Section {{ model.name }}
                                                </a>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </aside>
                            <div class="x_with_side_bar overflowXinherit" :class="{ 'col-12': sectionList.length <= 1 }">
                                <kr-search
                                    name="modules_listing"
                                    :url="{
                                        url: '/courses/' + course.uuid + '/modules',
                                    }"
                                    :columns="{
                                        name: 'display:Module|sortable:false|width:30%',
                                        startsFrom: 'display:Starts Form|sortable:false|width:15%|mobile:hide',
                                        endsOn: 'display:Ends On |sortable:false|width:15%|mobile:hide',
                                    }"
                                    :options="{
                                        archived_field: false,
                                        search_field: false,
                                        thead_title: false,
                                        thead: false,
                                        sort: '',
                                        order: '',
                                        page_number: false,
                                        query_fields: query_fields,
                                    }"
                                    @retrieve-data="processRetrieveModulesData"
                                >
                                    <template #lower-left>
                                        <div class="flexOnly align-items">
                                            <div class="viewFilter">
                                                <div class="form-group displayContent">
                                                    <div class="form-dropdown viewFilter marginLeft0">
                                                        <select
                                                            v-model="query_fields.isArchived"
                                                            class="form-control"
                                                            :aria-label="
                                                                query_fields.isArchived == 0
                                                                    ? 'Show active modules and activities'
                                                                    : 'Show archived modules and activities'
                                                            "
                                                            @change="searchModule"
                                                        >
                                                            <option value="0">Active</option>
                                                            <option value="1">Archived</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                v-if="modules.length > 0 && $can('duplicate_modules_and_activities_to_another_course', 'Course-' + course.uuid)"
                                                class="marginLeft20"
                                            >
                                                <div class="checkboxField flexOnly alignFlexStart justifyCenter">
                                                    <input id="selectAllModules" v-model="selectAllModules" type="checkbox" @change="toggleModules" />
                                                    <label for="selectAllModules" class="control-label margin0">
                                                        <b>Select All Modules </b>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #upper-right>
                                        <router-link
                                            v-if="$can('manage_modules_and_activities', 'Course-' + course.uuid)"
                                            v-slot="{ navigate }"
                                            :to="{
                                                name: 'courses.modules.create',
                                                params: {
                                                    course_id: course.uuid,
                                                },
                                            }"
                                            custom
                                        >
                                            <button
                                                class="btn btn-success marginRight10"
                                                :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                @click="navigate"
                                            >
                                                <i class="fa fa-plus marginRight8" aria-hidden="true" />New Module
                                            </button>
                                        </router-link>

                                        <div class="dropdown">
                                            <button
                                                id="viewModuleDropdownMenuButton"
                                                v-tooltip="generateMoreActionsButtonTooltip()"
                                                class="btn btn-danger"
                                                :class="{
                                                    'disabled hasToolTip': !canDuplicateModule() || selectedModules.length === 0,
                                                }"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                :aria-label="generateMoreActionsButtonTooltip()"
                                                :aria-disabled="!canDuplicateModule() || selectedModules.length === 0"
                                                @click.prevent="
                                                    !canDuplicateModule() || selectedModules.length === 0 ? '' : (isMoreOptionsOpened = !isMoreOptionsOpened)
                                                "
                                            >
                                                More Actions<i
                                                    :class="{
                                                        'fa fa-caret-up': isMoreOptionsOpened,
                                                        'fa fa-caret-down': !isMoreOptionsOpened,
                                                    }"
                                                    class="marginLeft8"
                                                    aria-hidden="true"
                                                />
                                            </button>

                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                <ul>
                                                    <li>
                                                        <a
                                                            class="primary-state"
                                                            href="#"
                                                            @click.prevent="
                                                                $refs['DuplicateModuleComponent'].duplicate(
                                                                    course,
                                                                    courseList,
                                                                    modules,
                                                                    selectedModules,
                                                                    $can('duplicate_modules_and_activities_to_another_course', 'Course-' + course.uuid)
                                                                )
                                                            "
                                                        >
                                                            <span>
                                                                <i class="fa fa-files-o marginRight8" aria-hidden="true" />Duplicate
                                                                {{ selectedModules.length == 0 ? 'Module' : 'Modules' }}
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                    <template #ndata="props">
                                        <tr>
                                            <td :colspan="props.model.total_column_count" class="padding0 borderNone">
                                                <template v-if="moduleSearchData.query.isArchived != '0'"> You have not archived any modules. </template>
                                                <template v-else>
                                                    <template v-if="$can('manage_modules_and_activities', 'Course-' + course.uuid)">
                                                        No modules yet,
                                                        <router-link
                                                            :to="{
                                                                name: 'courses.modules.create',
                                                                params: {
                                                                    course_id: course.uuid,
                                                                },
                                                            }"
                                                            tag="a"
                                                            class="link"
                                                        >
                                                            create module here.
                                                        </router-link>
                                                    </template>
                                                    <template v-else> No modules yet, please contact course owner to create modules. </template>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                    <template #adata="props">
                                        <template v-if="modules.length == 0">
                                            <tr>
                                                <td :colspan="props.model.total_column_count" class="padding0 borderNone">
                                                    <template v-if="$can('manage_modules_and_activities', 'Course-' + course.uuid)">
                                                        No modules yet,
                                                        <router-link
                                                            :to="{
                                                                name: 'courses.modules.create',
                                                                params: {
                                                                    course_id: course.uuid,
                                                                },
                                                            }"
                                                            tag="a"
                                                            class="link"
                                                        >
                                                            create module here.
                                                        </router-link>
                                                    </template>
                                                    <template v-else> No modules yet, please contact course owner to create modules. </template>
                                                </td>
                                            </tr>
                                        </template>

                                        <template v-for="(module, idx) in modules" :key="idx + 'modules'">
                                            <tr
                                                colspan="12"
                                                class="subContentTable moduleRow"
                                                :draggable="true"
                                                @dragover="allowDropToModule"
                                                @dragstart="dragModule($event, idx)"
                                                @drop="dropToModule($event, idx)"
                                                @dragleave="leaveDragFromModule"
                                                @dragend="
                                                    stopScroll = true;
                                                    currentDraggedItem = null;
                                                "
                                            >
                                                <td colspan="12" class="subContent">
                                                    <table class="table moduleTable">
                                                        <thead :id="module.uuid" tabindex="-1" class="subContent-header">
                                                            <tr>
                                                                <th colspan="12">
                                                                    <div :class="{ flexSpaceBetween: !isMobileView }">
                                                                        <div class="flex">
                                                                            <div
                                                                                :class="{
                                                                                    flex: !isMobileView,
                                                                                }"
                                                                            >
                                                                                <i
                                                                                    v-if="!isMobileView"
                                                                                    v-tooltip="{
                                                                                        content: 'Drag Me!',
                                                                                        show: isOpenModule[idx],
                                                                                        trigger: 'manual',
                                                                                    }"
                                                                                    class="fas fa-grip-vertical marginRight8"
                                                                                    aria-hidden="true"
                                                                                    @mouseover="isOpenModule[idx] = true"
                                                                                    @mouseleave="isOpenModule[idx] = false"
                                                                                    @mousedown="isOpenModule[idx] = false"
                                                                                />
                                                                                <span
                                                                                    v-if="
                                                                                        $can(
                                                                                            'duplicate_modules_and_activities_to_another_course',
                                                                                            'Course-' + course.uuid
                                                                                        )
                                                                                    "
                                                                                    class="checkboxField"
                                                                                >
                                                                                    <input
                                                                                        :id="'module-uuid_' + module.uuid"
                                                                                        v-model="selectedModules"
                                                                                        :name="module.uuid"
                                                                                        type="checkbox"
                                                                                        class="checkbox-field"
                                                                                        :value="module.uuid"
                                                                                        :aria-label="
                                                                                            activitiesCount(module) <= 1
                                                                                                ? 'course name ' +
                                                                                                  course.name +
                                                                                                  'module name ' +
                                                                                                  module.name +
                                                                                                  ' (' +
                                                                                                  activitiesCount(module) +
                                                                                                  'activity)'
                                                                                                : 'course name ' +
                                                                                                  course.name +
                                                                                                  'module name ' +
                                                                                                  module.name +
                                                                                                  ' (' +
                                                                                                  activitiesCount(module) +
                                                                                                  'activities)'
                                                                                        "
                                                                                    />
                                                                                    <label
                                                                                        :for="'module-uuid_' + module.uuid"
                                                                                        class="control-label visible-hidden"
                                                                                    >
                                                                                        Select Module
                                                                                    </label>
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <h2 class="fs-18px margin0">
                                                                                    <label :for="'module-uuid_' + module.uuid" class="fs-18px">
                                                                                        <span class="visible-hidden"> Module Name </span>
                                                                                        {{ module.name }}
                                                                                        (<span
                                                                                            v-text="
                                                                                                activitiesCount(module) <= 1
                                                                                                    ? activitiesCount(module) + ' activity'
                                                                                                    : activitiesCount(module) + ' activities'
                                                                                            "
                                                                                        />)
                                                                                    </label>
                                                                                </h2>
                                                                                <div class="marginTop10">
                                                                                    <span class="visible-hidden"> Module durations </span>

                                                                                    <div class="flexOnly alignBaseline moduleDurationsText fontGrey fw-normal">
                                                                                        <span class="visible-hidden"> Start date </span>
                                                                                        <i class="fas fa-calendar-alt marginRight8" aria-hidden="true" />
                                                                                        <span
                                                                                            class="marginRight5"
                                                                                            v-dompurify-html="parseReadableDate(module.startDate)"
                                                                                        />
                                                                                        -
                                                                                        <span class="visible-hidden"> End date </span>
                                                                                        <i
                                                                                            class="fas fa-calendar-alt marginLeft5 marginRight8"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                        <span v-dompurify-html="parseReadableDate(module.endDate)" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="buttonGroupInline" :class="{ marginTop10: isMobileView }">
                                                                            <template v-if="!module.isArchived">
                                                                                <router-link
                                                                                    v-slot="{ navigate }"
                                                                                    :to="{
                                                                                        name: 'courses.modules.activities.create',
                                                                                        params: {
                                                                                            course_id: course.uuid,
                                                                                            module_id: module.uuid,
                                                                                        },
                                                                                    }"
                                                                                    custom
                                                                                >
                                                                                    <button
                                                                                        v-tooltip="
                                                                                            !$can('manage_modules_and_activities', 'Course-' + course.uuid)
                                                                                                ? 'You do not have permission to add new activity'
                                                                                                : ''
                                                                                        "
                                                                                        class="btn btn-success"
                                                                                        :class="{
                                                                                            'disabled hasToolTip': !$can(
                                                                                                'manage_modules_and_activities',
                                                                                                'Course-' + course.uuid
                                                                                            ),
                                                                                        }"
                                                                                        :aria-disabled="
                                                                                            !$can('manage_modules_and_activities', 'Course-' + course.uuid)
                                                                                        "
                                                                                        @click="
                                                                                            $can('manage_modules_and_activities', 'Course-' + course.uuid)
                                                                                                ? navigate()
                                                                                                : null
                                                                                        "
                                                                                    >
                                                                                        <i class="fa fa-plus marginRight8" aria-hidden="true" />New Activity
                                                                                    </button>
                                                                                </router-link>

                                                                                <router-link
                                                                                    v-slot="{ navigate }"
                                                                                    :to="{
                                                                                        name: 'courses.modules.edit',
                                                                                        params: {
                                                                                            id: module.uuid,
                                                                                        },
                                                                                    }"
                                                                                    custom
                                                                                >
                                                                                    <button
                                                                                        v-tooltip="
                                                                                            !$can('manage_modules_and_activities', 'Course-' + course.uuid)
                                                                                                ? 'You do not have permission to edit modules'
                                                                                                : `Edit ${module.name} module`
                                                                                        "
                                                                                        class="btn btn-default primaryHover"
                                                                                        :class="{
                                                                                            'disabled hasToolTip': !$can(
                                                                                                'manage_modules_and_activities',
                                                                                                'Course-' + course.uuid
                                                                                            ),
                                                                                        }"
                                                                                        :aria-disabled="
                                                                                            !$can('manage_modules_and_activities', 'Course-' + course.uuid)
                                                                                        "
                                                                                        :aria-label="`Edit ${module.name} module`"
                                                                                        @click.prevent="
                                                                                            $can('manage_modules_and_activities', 'Course-' + course.uuid)
                                                                                                ? navigate()
                                                                                                : null
                                                                                        "
                                                                                    >
                                                                                        <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                                                                    </button>
                                                                                </router-link>
                                                                                <button
                                                                                    v-tooltip="generateDuplicateToolTip(module)"
                                                                                    class="btn btn-default primaryHover"
                                                                                    :class="{
                                                                                        'disabled hasToolTip': !canDuplicateModule(),
                                                                                    }"
                                                                                    :aria-disabled="!canDuplicateModule()"
                                                                                    :aria-label="generateDuplicateToolTip(module)"
                                                                                    @click.prevent="
                                                                                        !canDuplicateModule()
                                                                                            ? ''
                                                                                            : $refs['DuplicateModuleComponent'].duplicate(
                                                                                                  course,
                                                                                                  courseList,
                                                                                                  modules,
                                                                                                  [module.uuid],
                                                                                                  $can(
                                                                                                      'duplicate_modules_and_activities_to_another_course',
                                                                                                      'Course-' + course.uuid
                                                                                                  )
                                                                                              )
                                                                                    "
                                                                                >
                                                                                    <i class="fa fa-files-o" aria-hidden="true" />
                                                                                </button>
                                                                                <button
                                                                                    v-tooltip="generateArchiveToolTip(module)"
                                                                                    class="btn btn-default dangerHover"
                                                                                    :class="{
                                                                                        'disabled hasToolTip': !canArchiveModule(module),
                                                                                    }"
                                                                                    :aria-disabled="!canArchiveModule(module)"
                                                                                    :aria-label="generateArchiveToolTip(module)"
                                                                                    @click.prevent="
                                                                                        !canArchiveModule(module)
                                                                                            ? ''
                                                                                            : $refs['ArchiveModalComponent'].archive(module, 'modules')
                                                                                    "
                                                                                >
                                                                                    <i class="fa fa-archive" aria-hidden="true" />
                                                                                </button>
                                                                            </template>
                                                                            <button
                                                                                v-else
                                                                                v-tooltip="
                                                                                    !$can('manage_modules_and_activities', 'Course-' + course.uuid)
                                                                                        ? 'You do not have permission to reinstate modules'
                                                                                        : `Reinstate ${module.name} module`
                                                                                "
                                                                                class="btn btn-default dangerHover"
                                                                                :class="{
                                                                                    'disabled hasToolTip': !$can(
                                                                                        'manage_modules_and_activities',
                                                                                        'Course-' + course.uuid
                                                                                    ),
                                                                                }"
                                                                                :aria-disabled="!$can('manage_modules_and_activities', 'Course-' + course.uuid)"
                                                                                :aria-label="`Reinstate ${module.name} module`"
                                                                                @click.prevent="
                                                                                    !$can('manage_modules_and_activities', 'Course-' + course.uuid)
                                                                                        ? ''
                                                                                        : $refs['ReinstateModalComponent'].reinstate(module, 'modules')
                                                                                "
                                                                            >
                                                                                <i class="fa fa-refresh" aria-hidden="true" />
                                                                            </button>

                                                                            <button
                                                                                v-if="module._display !== false"
                                                                                class="btn minWidth5em alignRight marginLeft0"
                                                                                :aria-label="'Show Less of ' + module.name + ' table'"
                                                                                @click="
                                                                                    store.addHiddenModule({
                                                                                        courseUuid: course.uuid,
                                                                                        sectionUuid: query_fields.sectionUuid,
                                                                                        moduleUuid: module.uuid,
                                                                                    });
                                                                                    module._display = false;
                                                                                    props.model.splice();
                                                                                "
                                                                            >
                                                                                Less
                                                                                <i class="fa fa-caret-up marginLeft8" aria-hidden="true" />
                                                                            </button>
                                                                            <button
                                                                                v-else
                                                                                class="btn minWidth5em alignRight marginLeft0"
                                                                                :aria-label="'Show more of ' + module.name + ' table'"
                                                                                @click="
                                                                                    store.removeHiddenModule({
                                                                                        courseUuid: course.uuid,
                                                                                        sectionUuid: query_fields.sectionUuid,
                                                                                        moduleUuid: module.uuid,
                                                                                    });
                                                                                    module._display = true;
                                                                                    props.model.splice();
                                                                                "
                                                                            >
                                                                                More<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-if="module.activities.length > 0 && module._display !== false">
                                                                <tr class="hidden-xs hidden-sm">
                                                                    <td>
                                                                        <div class="overflowXinherit">
                                                                            <div class="table-responsive">
                                                                                <table class="table subTable border-collapse">
                                                                                    <caption class="visible-hidden">
                                                                                        {{
                                                                                            module.name
                                                                                        }}
                                                                                        activity table
                                                                                    </caption>

                                                                                    <thead>
                                                                                        <tr class="activityHeaders">
                                                                                            <th class="moduleActionHeader" style="width: 40px" />
                                                                                            <th
                                                                                                scope="col"
                                                                                                aria-label="activity name"
                                                                                                style="
                                                                                                    width: calc(
                                                                                                        100% - 128px - 134px - 147px - 85px - 320px - 41px
                                                                                                    );
                                                                                                "
                                                                                            >
                                                                                                Name
                                                                                                <template v-if="sort === 'name'">
                                                                                                    <button
                                                                                                        v-if="order === 'asc'"
                                                                                                        aria-label="ascending order"
                                                                                                        @click.prevent="toggle('name', idx)"
                                                                                                    >
                                                                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                                                                    </button>
                                                                                                    <button
                                                                                                        v-else
                                                                                                        aria-label="descending order"
                                                                                                        @click.prevent="toggle('name', idx)"
                                                                                                    >
                                                                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                                                                    </button>
                                                                                                </template>
                                                                                                <template v-else>
                                                                                                    <button
                                                                                                        aria-label="sort"
                                                                                                        @click.prevent="toggle('name', idx)"
                                                                                                    >
                                                                                                        <i class="fa fa-sort" aria-hidden="true" />
                                                                                                    </button>
                                                                                                </template>
                                                                                            </th>
                                                                                            <th scope="col" class="moduleActivityTable-type">Type</th>
                                                                                            <th scope="col" style="width: 134px">Modality</th>
                                                                                            <th scope="col" style="width: 147px">Status</th>
                                                                                            <th scope="col" style="width: 85px">Visibility</th>
                                                                                            <th scope="col" style="width: 320px">Actions</th>
                                                                                        </tr>
                                                                                    </thead>

                                                                                    <template
                                                                                        v-for="(activity, activity_idx) in module.activities"
                                                                                        :key="activity.uuid + '-d-activities'"
                                                                                    >
                                                                                        <tbody
                                                                                            :id="activity.uuid + '-d'"
                                                                                            class="activityTable"
                                                                                            tabindex="-1"
                                                                                            :draggable="true"
                                                                                            @dragover="allowDropToActivity"
                                                                                            @dragstart="dragActivity($event, idx, activity_idx)"
                                                                                            @drop="dropToActivity($event, idx, activity_idx)"
                                                                                            @dragleave="leaveDragFromActivity"
                                                                                            @dragend="
                                                                                                stopScroll = true;
                                                                                                currentDraggedItem = null;
                                                                                            "
                                                                                        >
                                                                                            <template v-if="!activity.isPublished">
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <i
                                                                                                            v-tooltip="{
                                                                                                                content: 'Drag Me!',
                                                                                                                show: isOpenActivity[idx + '_' + activity_idx],
                                                                                                                trigger: 'manual',
                                                                                                            }"
                                                                                                            class="fas fa-grip-vertical fontBlack fs-14px"
                                                                                                            style="padding-top: 1px"
                                                                                                            aria-hidden="true"
                                                                                                            @mouseover="
                                                                                                                isOpenActivity[idx + '_' + activity_idx] = true
                                                                                                            "
                                                                                                            @mouseleave="
                                                                                                                isOpenActivity[idx + '_' + activity_idx] = false
                                                                                                            "
                                                                                                            @mousedown="
                                                                                                                isOpenActivity[idx + '_' + activity_idx] = false
                                                                                                            "
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td scope="row">
                                                                                                        {{ activity.name }}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <template v-if="activity.type == 'prework'">
                                                                                                            Prework
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'irat'">
                                                                                                            IRAT
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'trat'">
                                                                                                            TRAT
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'iratntrat'">
                                                                                                            IRAT & TRAT
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'application'">
                                                                                                            Application Case
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'evaluation'">
                                                                                                            360 Degree Evaluation
                                                                                                        </template>
                                                                                                        <template
                                                                                                            v-if="activity.type == 'michaelsenEvaluation'"
                                                                                                        >
                                                                                                            Peer Evaluation: Michaelsen's Method
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'peerEvaluationV2'">
                                                                                                            Peer Evaluation
                                                                                                        </template>
                                                                                                    </td>
                                                                                                    <td class="capitalize">-</td>
                                                                                                    <td>
                                                                                                        <test-status :test="false" />
                                                                                                    </td>
                                                                                                    <td class="textCenteralize">
                                                                                                        <button
                                                                                                            v-tooltip="{
                                                                                                                content: 'Hidden from students continue setup',
                                                                                                            }"
                                                                                                            aria-disabled="true"
                                                                                                            class="btn btn-empty-border-none disabled hasToolTip"
                                                                                                        >
                                                                                                            <i class="fas fa-eye-slash" aria-hidden="true" />
                                                                                                        </button>
                                                                                                    </td>
                                                                                                    <td class="flexOnly align-items">
                                                                                                        <router-link
                                                                                                            v-if="
                                                                                                                $can(
                                                                                                                    'manage_modules_and_activities',
                                                                                                                    'Course-' + course.uuid
                                                                                                                )
                                                                                                            "
                                                                                                            v-slot="{ navigate }"
                                                                                                            :to="{
                                                                                                                name: 'courses.modules.activities.continue',
                                                                                                                params: {
                                                                                                                    id: activity.uuid,
                                                                                                                },
                                                                                                            }"
                                                                                                            custom
                                                                                                        >
                                                                                                            <button
                                                                                                                class="btn btn-outline-primary marginRight20"
                                                                                                                @click="navigate"
                                                                                                            >
                                                                                                                Continue Setup
                                                                                                            </button>
                                                                                                        </router-link>
                                                                                                        <div class="dropup">
                                                                                                            <button
                                                                                                                id="viewDropdownMenuButton"
                                                                                                                class="marginBottom0"
                                                                                                                data-toggle="dropdown"
                                                                                                                aria-haspopup="true"
                                                                                                                aria-expanded="false"
                                                                                                            >
                                                                                                                Actions<i
                                                                                                                    class="fa fa-caret-down marginLeft8"
                                                                                                                    aria-hidden="true"
                                                                                                                />
                                                                                                            </button>

                                                                                                            <div
                                                                                                                class="dropdown-menu dropdown-menu-right dropdown-menu-new"
                                                                                                                aria-labelledby="dropdownMenuButton"
                                                                                                            >
                                                                                                                <ul>
                                                                                                                    <li>
                                                                                                                        <a
                                                                                                                            v-tooltip="
                                                                                                                                !$can(
                                                                                                                                    'manage_modules_and_activities',
                                                                                                                                    'Course-' + course.uuid
                                                                                                                                )
                                                                                                                                    ? 'You are not allowed to duplicate activity'
                                                                                                                                    : ''
                                                                                                                            "
                                                                                                                            class="primary-state"
                                                                                                                            :class="{
                                                                                                                                'disabled hasToolTip': !$can(
                                                                                                                                    'manage_modules_and_activities',
                                                                                                                                    'Course-' + course.uuid
                                                                                                                                ),
                                                                                                                            }"
                                                                                                                            :href="
                                                                                                                                $can(
                                                                                                                                    'manage_modules_and_activities',
                                                                                                                                    'Course-' + course.uuid
                                                                                                                                )
                                                                                                                                    ? '#'
                                                                                                                                    : ''
                                                                                                                            "
                                                                                                                            @click.prevent="
                                                                                                                                $can(
                                                                                                                                    'manage_modules_and_activities',
                                                                                                                                    'Course-' + course.uuid
                                                                                                                                )
                                                                                                                                    ? $refs[
                                                                                                                                          'DuplicateActivityComponent'
                                                                                                                                      ].duplicate(activity)
                                                                                                                                    : ''
                                                                                                                            "
                                                                                                                            :aria-disabled="
                                                                                                                                !$can(
                                                                                                                                    'manage_modules_and_activities',
                                                                                                                                    'Course-' + course.uuid
                                                                                                                                )
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-files-o marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Duplicate Activity
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </li>

                                                                                                                    <hr
                                                                                                                        v-if="
                                                                                                                            activity.type !=
                                                                                                                                'peerEvaluationV2' &&
                                                                                                                            canDeleteActivity(activity, test)
                                                                                                                        "
                                                                                                                    />

                                                                                                                    <li
                                                                                                                        v-if="canDeleteActivity(activity, test)"
                                                                                                                    >
                                                                                                                        <a
                                                                                                                            class="danger-state"
                                                                                                                            href="#"
                                                                                                                            @click.prevent="
                                                                                                                                $refs[
                                                                                                                                    'DeleteActivityComponent'
                                                                                                                                ].deleteActivity(activity)
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-trash-o marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Delete Activity
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                <tr
                                                                                                    v-for="(test, test_idx) in activity.tests"
                                                                                                    :key="test_idx + 'test_activities'"
                                                                                                >
                                                                                                    <td
                                                                                                        v-if="test_idx == 0"
                                                                                                        :rowspan="activity.type == 'iratntrat' ? 2 : ''"
                                                                                                        :class="{
                                                                                                            'opacity-7':
                                                                                                                test.status == 'completed' ||
                                                                                                                test.status == 'ended',
                                                                                                        }"
                                                                                                    >
                                                                                                        <div class="flex">
                                                                                                            <i
                                                                                                                v-tooltip="{
                                                                                                                    content: 'Drag Me!',
                                                                                                                    show: isOpenActivity[
                                                                                                                        idx + '_' + activity_idx
                                                                                                                    ],
                                                                                                                    trigger: 'manual',
                                                                                                                }"
                                                                                                                class="fas fa-grip-vertical fontBlack fs-14px"
                                                                                                                style="padding-top: 1px"
                                                                                                                aria-hidden="true"
                                                                                                                @mouseover="
                                                                                                                    isOpenActivity[
                                                                                                                        idx + '_' + activity_idx
                                                                                                                    ] = true
                                                                                                                "
                                                                                                                @mouseleave="
                                                                                                                    isOpenActivity[
                                                                                                                        idx + '_' + activity_idx
                                                                                                                    ] = false
                                                                                                                "
                                                                                                                @mousedown="
                                                                                                                    isOpenActivity[
                                                                                                                        idx + '_' + activity_idx
                                                                                                                    ] = false
                                                                                                                "
                                                                                                            />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        :class="{
                                                                                                            'opacity-7':
                                                                                                                test.status == 'completed' ||
                                                                                                                test.status == 'ended',
                                                                                                        }"
                                                                                                        scope="row"
                                                                                                    >
                                                                                                        {{ test.name }}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        :class="{
                                                                                                            'opacity-7':
                                                                                                                test.status == 'completed' ||
                                                                                                                test.status == 'ended',
                                                                                                        }"
                                                                                                    >
                                                                                                        <template v-if="test.type == 'prework'">
                                                                                                            Prework
                                                                                                        </template>
                                                                                                        <template v-if="test.type == 'irat'"> IRAT </template>
                                                                                                        <template v-if="test.type == 'trat'"> TRAT </template>
                                                                                                        <template v-if="test.type == 'iratntrat'">
                                                                                                            IRAT & TRAT
                                                                                                        </template>
                                                                                                        <template v-if="test.type == 'application'">
                                                                                                            Application Case
                                                                                                        </template>
                                                                                                        <template v-if="test.type == 'evaluation'">
                                                                                                            360 Degree Evaluation
                                                                                                        </template>
                                                                                                        <template v-if="test.type == 'michaelsenEvaluation'">
                                                                                                            Peer Evaluation: Michaelsen's Method
                                                                                                        </template>
                                                                                                        <template v-if="test.type == 'peerEvaluationV2'">
                                                                                                            Peer Evaluation
                                                                                                        </template>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        class="capitalize"
                                                                                                        :class="{
                                                                                                            'opacity-7':
                                                                                                                test.status == 'completed' ||
                                                                                                                test.status == 'ended',
                                                                                                        }"
                                                                                                    >
                                                                                                        {{ getTestModality(test) }}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        :class="{
                                                                                                            'opacity-7':
                                                                                                                test.status == 'completed' ||
                                                                                                                test.status == 'ended',
                                                                                                        }"
                                                                                                    >
                                                                                                        <test-status :test="test" />
                                                                                                    </td>
                                                                                                    <td class="textCenteralize">
                                                                                                        <button
                                                                                                            v-if="
                                                                                                                [
                                                                                                                    'not started',
                                                                                                                    'scheduled',
                                                                                                                    'ended',
                                                                                                                    'completed',
                                                                                                                ].includes(test.status)
                                                                                                            "
                                                                                                            v-tooltip="
                                                                                                                !$can(
                                                                                                                    'manage_test_visibility',
                                                                                                                    'Course-' + course.uuid
                                                                                                                )
                                                                                                                    ? 'You are not allowed to toggle test visibility'
                                                                                                                    : test.isPublished
                                                                                                                    ? 'Shown to students'
                                                                                                                    : 'Hidden from students'
                                                                                                            "
                                                                                                            aria-label="Shown to students"
                                                                                                            class="btn btn-empty-border-none"
                                                                                                            :class="{
                                                                                                                'disabled hasToolTip': !$can(
                                                                                                                    'manage_test_visibility',
                                                                                                                    'Course-' + course.uuid
                                                                                                                ),
                                                                                                            }"
                                                                                                            @click="
                                                                                                                !$can(
                                                                                                                    'manage_test_visibility',
                                                                                                                    'Course-' + course.uuid
                                                                                                                )
                                                                                                                    ? ''
                                                                                                                    : $refs[
                                                                                                                          'VisibilityHiddenComponent'
                                                                                                                      ].hideActivity(test, test.isPublished)
                                                                                                            "
                                                                                                        >
                                                                                                            <i
                                                                                                                class="fas"
                                                                                                                :class="{
                                                                                                                    'fa-eye': test.isPublished,
                                                                                                                    'fa-eye-slash': !test.isPublished,
                                                                                                                }"
                                                                                                                aria-hidden="true"
                                                                                                            />
                                                                                                        </button>
                                                                                                    </td>
                                                                                                    <td class="flexOnly align-items">
                                                                                                        <template v-if="test.isPublished">
                                                                                                            <router-link
                                                                                                                v-slot="{ navigate }"
                                                                                                                :to="{
                                                                                                                    name:
                                                                                                                        test.type != 'peerEvaluationV2'
                                                                                                                            ? 'activities.tests.dashboard'
                                                                                                                            : 'activities.tests.dashboard.peer-evaluation',
                                                                                                                    params: {
                                                                                                                        id: test.uuid,
                                                                                                                    },
                                                                                                                }"
                                                                                                                target="_self"
                                                                                                                custom
                                                                                                            >
                                                                                                                <button
                                                                                                                    v-tooltip="
                                                                                                                        !canGoToDashboard()
                                                                                                                            ? 'You are not allowed to view dashboard'
                                                                                                                            : ''
                                                                                                                    "
                                                                                                                    class="btn btn-primary marginRight20"
                                                                                                                    :class="{
                                                                                                                        'disabled hasToolTip':
                                                                                                                            !canGoToDashboard(),
                                                                                                                    }"
                                                                                                                    @click.prevent="
                                                                                                                        canGoToDashboard() ? navigate() : ''
                                                                                                                    "
                                                                                                                >
                                                                                                                    Go to Dashboard
                                                                                                                </button>
                                                                                                            </router-link>
                                                                                                        </template>
                                                                                                        <template v-else>
                                                                                                            <router-link
                                                                                                                v-slot="{ navigate }"
                                                                                                                :to="{
                                                                                                                    name:
                                                                                                                        test.type != 'peerEvaluationV2'
                                                                                                                            ? 'activities.tests.dashboard'
                                                                                                                            : 'activities.tests.dashboard.peer-evaluation',
                                                                                                                    params: {
                                                                                                                        id: test.uuid,
                                                                                                                    },
                                                                                                                }"
                                                                                                                target="_self"
                                                                                                                custom
                                                                                                            >
                                                                                                                <button
                                                                                                                    v-tooltip="
                                                                                                                        !canGoToDashboard()
                                                                                                                            ? 'You are not allowed to view dashboard'
                                                                                                                            : ''
                                                                                                                    "
                                                                                                                    class="btn btn-primary marginRight20"
                                                                                                                    :class="{
                                                                                                                        'disabled hasToolTip':
                                                                                                                            !canGoToDashboard(),
                                                                                                                    }"
                                                                                                                    @click.prevent="
                                                                                                                        canGoToDashboard() ? navigate() : ''
                                                                                                                    "
                                                                                                                >
                                                                                                                    Go to Dashboard
                                                                                                                </button>
                                                                                                            </router-link>
                                                                                                        </template>

                                                                                                        <div class="dropup">
                                                                                                            <button
                                                                                                                id="viewDropdownMenuButton"
                                                                                                                data-toggle="dropdown"
                                                                                                                aria-haspopup="true"
                                                                                                                aria-expanded="false"
                                                                                                            >
                                                                                                                Actions<i
                                                                                                                    class="fa fa-caret-down marginLeft8"
                                                                                                                    aria-hidden="true"
                                                                                                                />
                                                                                                            </button>

                                                                                                            <div
                                                                                                                class="dropdown-menu dropdown-menu-right dropdown-menu-new"
                                                                                                                aria-labelledby="dropdownMenuButton"
                                                                                                            >
                                                                                                                <ul>
                                                                                                                    <li>
                                                                                                                        <a
                                                                                                                            class="primary-state"
                                                                                                                            href="#"
                                                                                                                            :aria-label="
                                                                                                                                'Create ' +
                                                                                                                                activity.name +
                                                                                                                                ' as a Template'
                                                                                                                            "
                                                                                                                            @click.prevent="
                                                                                                                                $refs[
                                                                                                                                    'CreateTemplateComponent'
                                                                                                                                ].createFromActivity(activity)
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-plus marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Create as a Template
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </li>
                                                                                                                    <li>
                                                                                                                        <a
                                                                                                                            v-tooltip="
                                                                                                                                !$can(
                                                                                                                                    'manage_modules_and_activities',
                                                                                                                                    'Course-' + course.uuid
                                                                                                                                )
                                                                                                                                    ? 'You are not allowed to duplicate activity'
                                                                                                                                    : ''
                                                                                                                            "
                                                                                                                            class="primary-state"
                                                                                                                            :class="{
                                                                                                                                'disabled hasToolTip': !$can(
                                                                                                                                    'manage_modules_and_activities',
                                                                                                                                    'Course-' + course.uuid
                                                                                                                                ),
                                                                                                                            }"
                                                                                                                            :href="
                                                                                                                                $can(
                                                                                                                                    'manage_modules_and_activities',
                                                                                                                                    'Course-' + course.uuid
                                                                                                                                )
                                                                                                                                    ? '#'
                                                                                                                                    : ''
                                                                                                                            "
                                                                                                                            @click.prevent="
                                                                                                                                $can(
                                                                                                                                    'manage_modules_and_activities',
                                                                                                                                    'Course-' + course.uuid
                                                                                                                                )
                                                                                                                                    ? $refs[
                                                                                                                                          'DuplicateActivityComponent'
                                                                                                                                      ].duplicate(activity)
                                                                                                                                    : ''
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-files-o marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Duplicate Activity
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </li>

                                                                                                                    <li
                                                                                                                        v-if="
                                                                                                                            canDisplayExportGradesButton(
                                                                                                                                activity,
                                                                                                                                test
                                                                                                                            )
                                                                                                                        "
                                                                                                                    >
                                                                                                                        <a
                                                                                                                            class="primary-state"
                                                                                                                            href="#"
                                                                                                                            @click.prevent="
                                                                                                                                $refs[
                                                                                                                                    'GenerateGradesComponent'
                                                                                                                                ].generate(test, 'activity')
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fas fa-download marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Export Grades
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </li>

                                                                                                                    <hr
                                                                                                                        v-if="
                                                                                                                            canResetActivity(activity, test) ||
                                                                                                                            canDeleteActivity(activity, test)
                                                                                                                        "
                                                                                                                    />

                                                                                                                    <li v-if="canResetActivity(activity, test)">
                                                                                                                        <a
                                                                                                                            class="danger-state"
                                                                                                                            href="#"
                                                                                                                            @click.prevent="
                                                                                                                                $refs[
                                                                                                                                    'ResetActivityComponent'
                                                                                                                                ].resetActivity(activity)
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-refresh marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Reset Activity
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </li>

                                                                                                                    <li
                                                                                                                        v-if="canDeleteActivity(activity, test)"
                                                                                                                    >
                                                                                                                        <a
                                                                                                                            class="danger-state"
                                                                                                                            href="#"
                                                                                                                            @click.prevent="
                                                                                                                                $refs[
                                                                                                                                    'DeleteActivityComponent'
                                                                                                                                ].deleteActivity(activity)
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-trash-o marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Delete Activity
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </template>
                                                                                        </tbody>
                                                                                    </template>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <div class="hidden-md hidden-lg padding20">
                                                                    <template v-for="activity in module.activities" :key="activity.uuid + '-m'">
                                                                        <div :id="activity.uuid + '-m'" class="subContentDetail" tabindex="-1">
                                                                            <div class="header">
                                                                                <div>
                                                                                    <div class="inline-flex">
                                                                                        <div class="btn-grey">
                                                                                            <template v-if="activity.type == 'prework'"> Prework </template>
                                                                                            <template v-if="activity.type == 'irat'"> IRAT </template>
                                                                                            <template v-if="activity.type == 'trat'"> TRAT </template>
                                                                                            <template v-if="activity.type == 'iratntrat'">
                                                                                                IRAT & TRAT
                                                                                            </template>
                                                                                            <template v-if="activity.type == 'application'">
                                                                                                Application Case
                                                                                            </template>
                                                                                            <template v-if="activity.type == 'evaluation'">
                                                                                                360 Degree Evaluation
                                                                                            </template>
                                                                                            <template v-if="activity.type == 'michaelsenEvaluation'">
                                                                                                Peer Evaluation: Michaelsen's Method
                                                                                            </template>
                                                                                            <template v-if="activity.type == 'peerEvaluationV2'">
                                                                                                Peer Evaluation
                                                                                            </template>
                                                                                        </div>
                                                                                        <div class="blueTitle">
                                                                                            {{ activity.name }}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="!activity.isPublished" class="content">
                                                                                <div class="subContents">
                                                                                    <!--<div class="subContentHeader">Section1</div>-->
                                                                                    <div class="subContentDetails">
                                                                                        <div class="form-control-static flexSpaceBetween">
                                                                                            <label class="control-label">TYPE</label>
                                                                                            <div class="greyText">
                                                                                                <template v-if="activity.type == 'prework'"> Prework </template>
                                                                                                <template v-if="activity.type == 'irat'"> IRAT </template>
                                                                                                <template v-if="activity.type == 'trat'"> TRAT </template>
                                                                                                <template v-if="activity.type == 'iratntrat'">
                                                                                                    IRAT & TRAT
                                                                                                </template>
                                                                                                <template v-if="activity.type == 'application'">
                                                                                                    Application Case
                                                                                                </template>
                                                                                                <template v-if="activity.type == 'evaluation'">
                                                                                                    360 Degree Evaluation
                                                                                                </template>
                                                                                                <template v-if="activity.type == 'michaelsenEvaluation'">
                                                                                                    Peer Evaluation: Michaelsen's Method
                                                                                                </template>
                                                                                                <template v-if="activity.type == 'peerEvaluationV2'">
                                                                                                    Peer Evaluation
                                                                                                </template>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="form-control-static flexSpaceBetween">
                                                                                            <label class="control-label">Modality</label>
                                                                                            <p class="greyText capitalize">-</p>
                                                                                        </div>
                                                                                        <div>
                                                                                            <test-status :test="test" />
                                                                                        </div>
                                                                                        <div
                                                                                            v-if="
                                                                                                $can('manage_modules_and_activities', 'Course-' + course.uuid)
                                                                                            "
                                                                                            class="marginTop20"
                                                                                        >
                                                                                            <router-link
                                                                                                v-slot="{ navigate }"
                                                                                                :to="{
                                                                                                    name: 'courses.modules.activities.continue',
                                                                                                    params: {
                                                                                                        id: activity.uuid,
                                                                                                    },
                                                                                                }"
                                                                                                custom
                                                                                            >
                                                                                                <button class="btn btn-default primaryHover" @click="navigate">
                                                                                                    Continue Setup
                                                                                                </button>
                                                                                            </router-link>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div v-else class="content">
                                                                                <div class="subContents">
                                                                                    <div
                                                                                        v-for="(test, idx) in activity.tests"
                                                                                        :key="idx + 'activity-test-module-activities'"
                                                                                        class="subContentDetails"
                                                                                    >
                                                                                        <div>
                                                                                            <div class="form-control-static flexSpaceBetween">
                                                                                                <label class="control-label">Type</label>
                                                                                                <p class="greyText">
                                                                                                    <template v-if="test.type == 'prework'"> Prework </template>
                                                                                                    <template v-if="test.type == 'irat'"> IRAT </template>
                                                                                                    <template v-if="test.type == 'trat'"> TRAT </template>
                                                                                                    <template v-if="test.type == 'iratntrat'">
                                                                                                        IRAT & TRAT
                                                                                                    </template>
                                                                                                    <template v-if="test.type == 'application'">
                                                                                                        Application Case
                                                                                                    </template>
                                                                                                    <template v-if="test.type == 'evaluation'">
                                                                                                        360 Degree Evaluation
                                                                                                    </template>
                                                                                                    <template v-if="test.type == 'michaelsenEvaluation'">
                                                                                                        Peer Evaluation: Michaelsen's Method
                                                                                                    </template>
                                                                                                    <template v-if="test.type == 'peerEvaluationV2'">
                                                                                                        Peer Evaluation
                                                                                                    </template>
                                                                                                </p>
                                                                                            </div>
                                                                                            <div class="form-control-static flexSpaceBetween">
                                                                                                <label class="control-label">Modality</label>
                                                                                                <p class="greyText capitalize">
                                                                                                    {{ getTestModality(test) }}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div>
                                                                                            <template v-if="!test.isPublished">
                                                                                                <div class="col-xs-12 btn statusTagDanger">
                                                                                                    Hidden from students
                                                                                                </div>
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                <test-status :test="test" />
                                                                                            </template>
                                                                                        </div>
                                                                                        <div class="marginTop20 inlineFlex">
                                                                                            <template v-if="test.isPublished">
                                                                                                <router-link
                                                                                                    v-slot="{ navigate }"
                                                                                                    :to="{
                                                                                                        name:
                                                                                                            test.type != 'peerEvaluationV2'
                                                                                                                ? 'activities.tests.dashboard'
                                                                                                                : 'activities.tests.dashboard.peer-evaluation',
                                                                                                        params: {
                                                                                                            id: test.uuid,
                                                                                                        },
                                                                                                    }"
                                                                                                    target="_self"
                                                                                                    custom
                                                                                                >
                                                                                                    <button
                                                                                                        v-tooltip="
                                                                                                            !canGoToDashboard()
                                                                                                                ? 'You are not allowed to view dashboard'
                                                                                                                : ''
                                                                                                        "
                                                                                                        class="btn btn-primary"
                                                                                                        :class="{
                                                                                                            'disabled hasToolTip': !canGoToDashboard(),
                                                                                                        }"
                                                                                                        @click.prevent="canGoToDashboard() ? navigate() : ''"
                                                                                                    >
                                                                                                        Go to Dashboard
                                                                                                    </button>
                                                                                                </router-link>
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                <router-link
                                                                                                    v-slot="{ navigate }"
                                                                                                    :to="{
                                                                                                        name:
                                                                                                            test.type != 'peerEvaluationV2'
                                                                                                                ? 'activities.tests.dashboard'
                                                                                                                : 'activities.tests.dashboard.peer-evaluation',
                                                                                                        params: {
                                                                                                            id: test.uuid,
                                                                                                        },
                                                                                                    }"
                                                                                                    target="_self"
                                                                                                    custom
                                                                                                >
                                                                                                    <button
                                                                                                        v-tooltip="
                                                                                                            !canGoToDashboard()
                                                                                                                ? 'You are not allowed to view dashboard'
                                                                                                                : ''
                                                                                                        "
                                                                                                        class="btn btn-primary"
                                                                                                        :class="{
                                                                                                            'disabled hasToolTip': !canGoToDashboard(),
                                                                                                        }"
                                                                                                        @click.prevent="canGoToDashboard() ? navigate() : ''"
                                                                                                    >
                                                                                                        Go to Dashboard
                                                                                                    </button>
                                                                                                </router-link>
                                                                                            </template>

                                                                                            <div class="dropup marginLeft20">
                                                                                                <button
                                                                                                    id="viewDropdownMenuButton"
                                                                                                    v-tooltip="
                                                                                                        !can('manage_test', 'Course-' + course.uuid)
                                                                                                            ? 'You are not allowed to manage activity'
                                                                                                            : ''
                                                                                                    "
                                                                                                    :class="{
                                                                                                        'disabled hasToolTip': !can(
                                                                                                            'manage_test',
                                                                                                            'Course-' + course.uuid
                                                                                                        ),
                                                                                                    }"
                                                                                                    :aria-disabled="
                                                                                                        !can('manage_test', 'Course-' + course.uuid)
                                                                                                    "
                                                                                                    data-toggle="dropdown"
                                                                                                    aria-haspopup="true"
                                                                                                    aria-expanded="false"
                                                                                                >
                                                                                                    Actions<i
                                                                                                        class="fa fa-caret-down marginLeft8"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                </button>

                                                                                                <div
                                                                                                    class="dropdown-menu dropdown-menu-right dropdown-menu-new"
                                                                                                    aria-labelledby="dropdownMenuButton"
                                                                                                >
                                                                                                    <ul>
                                                                                                        <li v-if="activity.type != 'peerEvaluationV2'">
                                                                                                            <a
                                                                                                                v-tooltip="
                                                                                                                    !can(
                                                                                                                        'manage_modules_and_activities',
                                                                                                                        'Course-' + course.uuid
                                                                                                                    )
                                                                                                                        ? 'You are not allowed to duplicate activity'
                                                                                                                        : ''
                                                                                                                "
                                                                                                                class="primary-state"
                                                                                                                :class="{
                                                                                                                    'disabled hasToolTip': !can(
                                                                                                                        'manage_modules_and_activities',
                                                                                                                        'Course-' + course.uuid
                                                                                                                    ),
                                                                                                                }"
                                                                                                                :href="
                                                                                                                    can(
                                                                                                                        'manage_modules_and_activities',
                                                                                                                        'Course-' + course.uuid
                                                                                                                    )
                                                                                                                        ? '#'
                                                                                                                        : ''
                                                                                                                "
                                                                                                                @click.prevent="
                                                                                                                    can(
                                                                                                                        'manage_modules_and_activities',
                                                                                                                        'Course-' + course.uuid
                                                                                                                    )
                                                                                                                        ? $refs[
                                                                                                                              'DuplicateActivityComponent'
                                                                                                                          ].duplicate(activity)
                                                                                                                        : ''
                                                                                                                "
                                                                                                            >
                                                                                                                <span>
                                                                                                                    <i
                                                                                                                        class="fa fa-files-o marginRight8"
                                                                                                                        aria-hidden="true"
                                                                                                                    />Duplicate Activity
                                                                                                                </span>
                                                                                                            </a>
                                                                                                        </li>

                                                                                                        <li v-if="canDisplayExportGradesButton(activity, test)">
                                                                                                            <a
                                                                                                                class="primary-state"
                                                                                                                href="#"
                                                                                                                @click.prevent="
                                                                                                                    $refs['GenerateGradesComponent'].generate(
                                                                                                                        test,
                                                                                                                        'activity'
                                                                                                                    )
                                                                                                                "
                                                                                                            >
                                                                                                                <span>
                                                                                                                    <i
                                                                                                                        class="fas fa-download marginRight8"
                                                                                                                        aria-hidden="true"
                                                                                                                    />Export Grades
                                                                                                                </span>
                                                                                                            </a>
                                                                                                        </li>

                                                                                                        <hr
                                                                                                            v-if="
                                                                                                                canResetActivity(activity, test) ||
                                                                                                                canDeleteActivity(activity, test)
                                                                                                            "
                                                                                                        />

                                                                                                        <li v-if="canResetActivity(activity, test)">
                                                                                                            <a
                                                                                                                class="danger-state"
                                                                                                                href="#"
                                                                                                                @click.prevent="
                                                                                                                    $refs[
                                                                                                                        'ResetActivityComponent'
                                                                                                                    ].resetActivity(activity)
                                                                                                                "
                                                                                                            >
                                                                                                                <span>
                                                                                                                    <i
                                                                                                                        class="fa fa-refresh marginRight8"
                                                                                                                        aria-hidden="true"
                                                                                                                    />Reset Activity
                                                                                                                </span>
                                                                                                            </a>
                                                                                                        </li>

                                                                                                        <li v-if="canDeleteActivity(activity, test)">
                                                                                                            <a
                                                                                                                class="danger-state"
                                                                                                                @click.prevent="
                                                                                                                    $refs[
                                                                                                                        'DeleteActivityComponent'
                                                                                                                    ].deleteActivity(activity)
                                                                                                                "
                                                                                                            >
                                                                                                                <span>
                                                                                                                    <i
                                                                                                                        class="fa fa-trash-o marginRight8"
                                                                                                                        aria-hidden="true"
                                                                                                                    />
                                                                                                                    Delete Activity
                                                                                                                </span>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </template>
                                                            <template v-else-if="module.activities.length == 0 && module._display !== false">
                                                                <tr>
                                                                    <td :colspan="props.model.total_column_count" class="borderNone">
                                                                        <template v-if="$can('manage_modules_and_activities', 'Course-' + course.uuid)">
                                                                            <span v-if="!module.isArchived && hasWritePrivilege(course)">
                                                                                No activities yet,
                                                                                <router-link
                                                                                    :to="{
                                                                                        name: 'courses.modules.activities.create',
                                                                                        params: {
                                                                                            course_id: course.uuid,
                                                                                            module_id: module.uuid,
                                                                                        },
                                                                                    }"
                                                                                    tag="a"
                                                                                    class="link"
                                                                                    aria-label="Create activity page"
                                                                                >
                                                                                    Create an activity here
                                                                                </router-link>
                                                                            </span>
                                                                            <span v-else> No activity. </span>
                                                                        </template>
                                                                        <template v-else>
                                                                            <p>
                                                                                Your Role as
                                                                                <b>{{
                                                                                    $ability.relevantRuleFor('get_role', 'Course-' + course.uuid)?.conditions
                                                                                        ?.role
                                                                                }}</b>
                                                                                does not grant you permission to add new activity
                                                                            </p>
                                                                        </template>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </kr-search>
                            </div>
                        </div>
                    </template>
                </kr-panel>
                <kr-panel v-else :with-footer="false" :with-title="false" class="new-panel">
                    <template #content>
                        <div class="padding20">
                            <p>
                                Your Role as <b>{{ $ability.relevantRuleFor('get_role', 'Course-' + course.uuid)?.conditions?.role }}</b> does not grant you
                                permission to view this.
                            </p>
                        </div>
                    </template>
                </kr-panel>
            </template>
            <template v-else-if="currentTab == 'student'">
                <manage-students :course-obj="course" @update-manage-accommodations="lookForValue" @get-back-to-module-tab="getBackToModule" />
            </template>
            <template v-else-if="currentTab == 'downloadables'">
                <kr-panel v-if="$can('export_grades', 'Course-' + course.uuid)" :with-footer="false">
                    <template #title>
                        <div class="col-xs-12 col-sm-6">
                            <h2>Grades</h2>
                        </div>
                        <div class="col-xs-12 col-sm-6" :class="{ textAlignRight: !isBelow768px, marginTop10: isBelow768px }">
                            <button
                                v-tooltip="generateGradesTooltip"
                                class="btn btn-default primaryHover"
                                :class="{ 'disabled hasToolTip': isGenerateGradesDisabled }"
                                :aria-disabled="isGenerateGradesDisabled ? true : undefined"
                                @click="isGenerateGradesDisabled ? '' : $refs['GenerateGradesComponent'].generate(course)"
                            >
                                <i class="fa fa-download marginRight8" aria-hidden="true" />Generate Course Grades Report
                            </button>
                        </div>
                    </template>
                    <template #content>
                        <kr-search
                            name="downloadables_listing"
                            :url="{
                                url: `/courses/${course.uuid}/downloadables`,
                            }"
                            :columns="{
                                name: 'display:Name|sortable:false',
                                section: 'display:Section|sortable:false',
                                test_type: 'display:Type|sortable:false|width:140px',
                                generated_at: 'display:Generated On|sortable:false|width:280px',
                                generated_by_user: 'display:Generated By|sortable:false|width:145px',
                                status: 'display:Status|sortable:false|width:125px',
                                action: 'display:Actions|sortable:false|width:145px',
                            }"
                            :options="{
                                archived_field: false,
                                drop_page_number: false,
                                search_field: false,
                                thead_title: false,
                                hide_table_if_empty: true,
                                top_bar: false,
                                action_column: false,
                            }"
                        >
                            <template #cdata="props">
                                <td>{{ props.model.name }}</td>
                                <td>{{ props.model.section_name }}</td>

                                <td>
                                    <template v-if="props.model.test_type == 'prework'"> Prework </template>
                                    <template v-else-if="props.model.test_type == 'irat'"> IRAT </template>
                                    <template v-else-if="props.model.test_type == 'trat'"> TRAT </template>
                                    <template v-else-if="props.model.test_type == 'iratntrat'"> IRAT & TRAT </template>
                                    <template v-else-if="props.model.test_type == 'application'"> Application Case </template>
                                    <template v-else-if="props.model.test_type == 'evaluation'"> 360 Degree Evaluation </template>
                                    <template v-else-if="props.model.test_type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen's Method </template>
                                    <template v-else-if="props.model.test_type == 'peerEvaluationV2'"> Peer Evaluation </template>
                                    <template v-else>
                                        {{ props.model.test_type }}
                                    </template>
                                </td>
                                <td>
                                    <template v-if="props.model.generated_at">
                                        <span v-dompurify-html="parseReadableDate(props.model.generated_at, true)" />
                                    </template>
                                    <template v-else> - </template>
                                </td>

                                <td>{{ props.model.generated_by_user }}</td>

                                <td>
                                    <template v-if="props.model.status == '0'">
                                        <div class="statusTag statusTagWarning">Pending</div>
                                    </template>
                                    <template v-else-if="props.model.status == '1'">
                                        <div class="statusTag statusTagSuccess">Ready</div>
                                    </template>
                                    <template v-else-if="props.model.status == '2'">
                                        <div class="statusTag statusTagDanger">Failed</div>
                                    </template>
                                </td>

                                <td>
                                    <div class="dropdown">
                                        <button id="viewDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <a class="primary-state" href="#" @click.prevent="downloadReport(props.model)">
                                                        <span>
                                                            <i class="fa fa-download marginRight8" aria-hidden="true" />Download Grades Report for
                                                            <template v-if="props.model.type == 'test_grade_report'"> Activity </template>
                                                            <template v-else> Course </template>
                                                        </span>
                                                    </a>
                                                </li>
                                                <hr />
                                                <li>
                                                    <a
                                                        class="danger-state"
                                                        href="#"
                                                        @click.prevent="$refs['DeleteReportComponent'].deleteReport(course, props.model)"
                                                    >
                                                        <span> <i class="fa fa-trash-o marginRight8" aria-hidden="true" />Delete Report </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </template>
                            <template #ndata="props">
                                <div>No grades to export</div>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
                <kr-panel v-else :with-footer="false" :with-title="false" class="new-panel">
                    <template #content>
                        <div class="padding20">
                            <p>
                                Your Role as <b>{{ $ability.relevantRuleFor('get_role', 'Course-' + course.uuid)?.conditions?.role }}</b> does not grant you
                                permission to view this.
                            </p>
                        </div>
                    </template>
                </kr-panel>
            </template>
            <template v-else-if="currentTab == 'overview'">
                <course-settings :course="course" @course-updated="syncCourseObject" />
                <enrollment-settings :course="course" />
                <lti1p3-settings v-if="canDisplayLti1p3" :course="course" />
            </template>
            <template v-else-if="currentTab == 'collaborators'">
                <manage-collaborators :course="course" @course-updated="fetch" />
            </template>
        </div>

        <createTemplateComponent ref="CreateTemplateComponent" />
        <generateGradesComponent ref="GenerateGradesComponent" />
        <deleteReportComponent ref="DeleteReportComponent" />
        <archiveModalComponent ref="ArchiveModalComponent" />
        <reinstateModalComponent ref="ReinstateModalComponent" />
        <duplicateModuleComponent ref="DuplicateModuleComponent" @clear-selected-modules="selectedModules = []" />
        <duplicateActivityComponent ref="DuplicateActivityComponent" />
        <deleteActivityComponent ref="DeleteActivityComponent" />
        <resetActivityComponent ref="ResetActivityComponent" />
        <visibilityHiddenComponent ref="VisibilityHiddenComponent" @is-test-published="moduleRefresh()" />
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
import { useAuthUserStore } from '../../../stores/auth';
import { lti1p3AccountsList } from '/src/lists/index';
import { useAbility } from '@casl/vue';

import createTemplateComponent from '../questions/library-templates/actions/createFromActivity.vue';
import generateGradesComponent from './actions/generateGrades.vue';
import deleteReportComponent from './actions/deleteReport.vue';
import archiveModalComponent from './actions/archiveModal.vue';
import reinstateModalComponent from './actions/reinstateModal.vue';
import duplicateModuleComponent from './actions/duplicateModule.vue';
import duplicateActivityComponent from './actions/duplicateActivity.vue';
import deleteActivityComponent from './actions/deleteActivity.vue';
import resetActivityComponent from './actions/resetActivity.vue';
import visibilityHiddenComponent from './actions/visibilityHidden.vue';

export default {
    components: {
        'manage-students': require(`./students/manage.vue`).default,
        'manage-collaborators': require(`./collaborators/index.vue`).default,
        'transfer-form': require(`./form-partials/partial.transfer.vue`).default,
        prediction: require(`./analytics/prediction.vue`).default,
        'question-optimization': require(`./analytics/question-optimization.vue`).default,
        'student-optimization': require(`./analytics/student-optimization.vue`).default,
        'course-settings': require(`./form-partials/partial.course-settings.vue`).default,
        'enrollment-settings': require(`./form-partials/partial.enrollment-settings.vue`).default,
        'lti1p3-settings': require(`./form-partials/partial.lti1p3-settings.vue`).default,
        createTemplateComponent,
        generateGradesComponent,
        deleteReportComponent,
        archiveModalComponent,
        reinstateModalComponent,
        duplicateModuleComponent,
        duplicateActivityComponent,
        deleteActivityComponent,
        resetActivityComponent,
        visibilityHiddenComponent,
    },
    data() {
        var auth = new KrAuth();
        let debouncedSubmit = _.debounce(this.updateOthers, 3000);
        return {
            store: useAuthUserStore(),
            component_done_loading: false,
            course: null,
            isMoreOptionsOpened: false,
            auth: auth,
            transfer_form: new KrForm({
                fields: {
                    userUuid: '',
                },
                props: {
                    successMessage: false,
                    failureMessage: false,
                },
            }),
            moduleSearchData: {
                query: { isTrashed: 0 },
                data: { data: { data: [] } },
            },
            modules: [],
            readOnlyLabel: 'You have read-only access',
            courseList: [],
            selectedCourse: false,
            selectedModules: [],
            query_fields: { isArchived: '0', sectionUuid: '' },
            selectAllModules: false,
            order: 'desc',
            sort: 'Name',
            sectionList: [],
            currentDraggedItem: null,
            stopScroll: false,
            isOpenModule: [],
            isOpenActivity: [],
            API_URL: auth.credential().API_URL,
            EXPRESS_SIGN_IN_URL: process.env.VUE_APP_EXPRESS_SIGN_IN_URL,
            form: new KrForm({
                fields: {
                    anonymous: '',
                    isSelfSignIn: '',
                    allowedDomains: '',
                    isSecretPin: '',
                    secretPin: '',
                    ssoGoogle: '',
                    ssoGoogleAllowedDomains: '',
                    ssoMicrosoft: '',
                    ssoMicrosoftAllowedDomains: '',
                },
            }),
            debouncedSubmit: debouncedSubmit,
            latestModelCopy: {},
            downloadedGrade: null,
            updateManageValue: false,
            tabs: ['module', 'student', 'downloadables', 'overview', 'collaborators'],
            currentTab: this.$route.query.tab || 'module',
            ability: useAbility(),
        };
    },
    computed: {
        canDisplayLti1p3() {
            if (lti1p3AccountsList.includes(this.auth.user().account.uuid) || process.env.VUE_APP_ENV == 'staging') {
                return true;
            }
            return false;
        },
        generateGradesTooltip() {
            if (!this.can('export_grades', 'Course-' + this.course.uuid)) {
                return 'You are not allowed to export grades';
            }

            if (this.course.students.length == 0 || this.course.activities.length == 0) {
                return 'No grades to generate';
            }
            return false;
        },
        isGenerateGradesDisabled() {
            return !this.can('export_grades', 'Course-' + this.course.uuid) || this.course.students.length == 0 || this.course.activities.length == 0;
        },
    },
    watch: {
        $route(to, from) {
            // react to route changes...
            var that = this;

            if (!$.isNumeric(to.hash.split('#')[1])) {
                this.fetch();
            }
        },
        selectedModules: {
            handler: function () {
                if (this.modules.length == this.selectedModules.length && this.modules.length != 0) {
                    this.selectAllModules = true;
                } else {
                    this.selectAllModules = false;
                }
            },
        },
        currentTab: {
            handler: function () {
                this.$router.replace({ query: { ...this.$route.query, tab: this.currentTab } }).then(() => {
                    this.breadCrumbTitle(this.currentTab);
                    this.tabStickyTop();
                });
            },
        },
        component_done_loading: {
            handler: function () {
                if (this.component_done_loading === true) {
                    setTimeout(() => {
                        this.tabStickyTop();
                        this.breadCrumbTitle(this.currentTab);
                    }, 100);
                }
            },
        },
    },
    created() {
        this.can = this.ability.can;

        this.tab = _.get(this, '$route.query.tab', 'module');
        var that = this;
        if (this.tab == 'permission') {
            this.tab = 'overview';
        }

        Events.fire('topbar_update', {
            breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }],
        });

        let courses = this.store.courses;
        let idx = _.findIndex(courses, function (o) {
            return o.uuid == that.$route.params.id;
        });
        if (idx != -1) {
            let selectedSectionUuid = courses[idx].selectedSectionUuid;
            if (selectedSectionUuid) {
                this.query_fields.sectionUuid = selectedSectionUuid;
            }
        }
        this.fetch();
        this.processEchoListeners();
    },
    methods: {
        processEchoListeners() {
            var that = this;
            let h1 = (e) => {
                if (e.courseUuid != that.course.uuid) {
                    return;
                }
                if (that.tab == 'downloadables') {
                    Events.fire('downloadables_listing_refresh');
                }
            };
            let c1 = window.Echo.private(`user.${this.auth.user().uuid}`).listen('DownloadableStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'DownloadableStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                for (var i = 0; i < that.moduleSearchData.data.data.data.length; i++) {
                    let module = that.moduleSearchData.data.data.data[i];
                    let activities = module.activities;

                    for (var j = 0; j < activities.length; j++) {
                        let tests = activities[j].tests;

                        let test = _.find(tests, function (o) {
                            return e.test.uuid == o.uuid;
                        });
                        if (test) {
                            test.status = e.test.status;
                            test.settings.startTime = e.test.settings.startTime;
                            test.settings.endTime = e.test.settings.endTime;
                            return;
                        }
                    }
                }
            };
            let c2 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('TestStatusUpdated', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'TestStatusUpdated',
                handler: h2,
            });

            let h3 = (e) => {
                Events.fire('modules_listing_refresh');
            };
            let c3 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('ActivityUnpublished', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'ActivityUnpublished',
                handler: h3,
            });

            let h4 = (e) => {
                Events.fire('modules_listing_refresh');
            };
            let c4 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('ActivityDeleted', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'ActivityDeleted',
                handler: h4,
            });

            let h5 = (e) => {
                if (e.courseUuid != that.course.uuid) {
                    return;
                }
                if (that.tab == 'downloadables') {
                    Events.fire('downloadables_listing_refresh');
                }
            };
            let c5 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('DownloadablesListUpdated', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'DownloadablesListUpdated',
                handler: h5,
            });

            let h6 = (e) => {
                for (var i = 0; i < that.moduleSearchData.data.data.data.length; i++) {
                    let module = that.moduleSearchData.data.data.data[i];
                    let activities = module.activities;

                    for (var j = 0; j < activities.length; j++) {
                        let tests = activities[j].tests;

                        let test = _.find(tests, function (o) {
                            return e.testUuid == o.uuid;
                        });
                        if (test) {
                            test.expectedEndingTime = e.expectedEndingTime;
                            test.startedEarly = true;
                            return;
                        }
                    }
                }
            };
            let c6 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('EarlyTestStarted', h6);
            this.echoChannels.push({
                channel: c6,
                event: 'EarlyTestStarted',
                handler: h6,
            });
            let h7 = (e) => {
                for (var i = 0; i < that.moduleSearchData.data.data.data.length; i++) {
                    let module = that.moduleSearchData.data.data.data[i];
                    let activities = module.activities;

                    for (var j = 0; j < activities.length; j++) {
                        let tests = activities[j].tests;

                        let test = _.find(tests, function (o) {
                            return e.testUuid == o.uuid;
                        });
                        if (test) {
                            test.expectedEndingTime = null;
                            return;
                        }
                    }
                }
            };
            let c7 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('EarlyTestEnded', h7);
            this.echoChannels.push({
                channel: c7,
                event: 'EarlyTestEnded',
                handler: h7,
            });

            let h8 = (e) => {
                Events.fire('modules_listing_refresh');
            };
            let c8 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('ActivityPublished', h8);
            this.echoChannels.push({
                channel: c8,
                event: 'ActivityPublished',
                handler: h8,
            });
        },
        processStatusDisplay(test) {
            if (!test) {
                return '';
            }
            if (_.get(test, 'settings.type') == 'synchronous') {
                if (_.get(test, 'status') == 'not started') {
                    return 'Not Started';
                } else if (_.get(test, 'status') == 'ongoing') {
                    if (!_.get(test, 'settings.hasTimeLimit')) {
                        return 'Opened';
                    } else {
                        //hasTimeLimit
                        return '[00:00:00]';
                    }
                } else if (_.get(test, 'status') == 'paused') {
                    return 'Paused';
                } else if (['ended', 'completed'].includes(_.get(test, 'status'))) {
                    return 'Closed';
                }
            } else if (_.get(test, 'settings.type') == 'asynchronous') {
                if (_.get(test, 'status') == 'not started') {
                    return 'Starts in {x}';
                } else if (_.get(test, 'status') == 'ongoing') {
                    return 'Opened';
                } else if (['ended', 'completed'].includes(_.get(test, 'status'))) {
                    return 'Closed';
                }
            }
        },
        array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        },
        dragFunction(e) {
            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };

            this.stopScroll = true;
            if (e.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (e.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        updateModulesAndActivitiesOrder(type = 'module', order, uuids = { uuid: '', moduleUuid: '' }) {
            axios.post(`courses/${this.course.uuid}/modules/reorder`, {
                type: type,
                order: order,
                ...uuids,
            });
        },
        dropToTest(moduleIdx, activityIdx, testIdx, offset) {
            let testUuid = this.modules[moduleIdx].activities[activityIdx].tests[testIdx].uuid;
            this.array_move(this.modules[moduleIdx].activities[activityIdx].tests, testIdx, testIdx + offset);
            this.updateModulesAndActivitiesOrder('test', testIdx + offset + 1, {
                uuid: testUuid,
            });
        },
        dropToModule(event, moduleIdx) {
            var data = JSON.parse(event.dataTransfer.getData('text'));
            this.currentDraggedItem = null;
            if (data.is != 'module' && !(data.is == 'activity' && data.moduleIdx != moduleIdx)) {
                return;
            }
            this.currentDraggedItem = null;
            if (event.stopPropagation) {
                event.stopPropagation();
            }
            var target = $(event.target).closest('.moduleTable')[0];
            $(target).removeClass('selected-bottom');
            $(target).removeClass('selected-top');
            if (data.is == 'module') {
                this.array_move(this.modules, data.moduleIdx, moduleIdx);
            } else {
                if (this.modules[moduleIdx].activities.length == 0) {
                    this.dropToActivity(event, moduleIdx, 0);
                }
                return;
            }
            let uuids = {};
            let order = moduleIdx + 1;
            uuids.uuid = data.uuid;
            if (data.is == 'activity') {
                uuids.moduleUuid = this.modules[moduleIdx].uuid;
                order = this.modules[moduleIdx].activities.length;
            }
            this.updateModulesAndActivitiesOrder(data.is, order, uuids);
        },
        leaveDragFromModule(event) {
            var target = $(event.target).closest('.moduleTable')[0];
            if ($(target).hasClass('moduleTable')) {
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
            }
        },
        allowDropToModule(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
            //window.requestAnimationFrame(function(){
            //qgroup to qgroup sorting.
            if ($(this.currentDraggedItem).hasClass('moduleRow')) {
                var target = $(event.target).closest('.moduleTable')[0];
                if (!target) {
                    return;
                }
                if (!target.isSameNode(this.currentDraggedItem)) {
                    var bounding = target.getBoundingClientRect();
                    var offset = bounding.y + bounding.height / 2;
                    if (event.clientY - offset > 0) {
                        $(target).removeClass('selected-top');
                        $(target).addClass('selected-bottom');
                    } else {
                        $(target).removeClass('selected-bottom');
                        $(target).addClass('selected-top');
                    }
                }
            }

            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };

            this.stopScroll = true;
            if (event.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (event.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        dragModule(event, moduleIdx) {
            if (this.currentDraggedItem != null) {
                return;
            }
            event.dataTransfer.setData(
                'text',
                JSON.stringify({
                    is: 'module',
                    uuid: this.modules[moduleIdx].uuid,
                    moduleIdx: moduleIdx,
                })
            );
            event.dataTransfer.dropEffect = 'move';
            this.currentDraggedItem = event.target;
        },
        dropToActivity(event, moduleIdx, activityIdx) {
            var data = JSON.parse(event.dataTransfer.getData('text'));
            this.currentDraggedItem = null;
            if (data.is != 'activity') {
                return;
            }
            if (event.preventDefault) {
                event.preventDefault();
            }

            var target = $(event.target).closest('.activityTable')[0];
            if ($(target).hasClass('selected-bottom') && moduleIdx != data.moduleIdx) {
                activityIdx++;
            }
            $(target).removeClass('selected-bottom');
            $(target).removeClass('selected-top');
            let uuids = {};
            uuids.uuid = data.uuid;
            if (moduleIdx == data.moduleIdx) {
                this.array_move(this.modules[data.moduleIdx].activities, data.activityIdx, activityIdx);
            } else {
                uuids.moduleUuid = this.modules[moduleIdx].uuid;
                let a = _.clone(this.modules[data.moduleIdx].activities[data.activityIdx]);
                this.modules[data.moduleIdx].activities.splice(data.activityIdx, 1);
                this.modules[moduleIdx].activities.push(a);
                let l = this.modules[moduleIdx].activities.length - 1;
                this.array_move(this.modules[moduleIdx].activities, l, activityIdx);
            }
            this.updateModulesAndActivitiesOrder(data.is, activityIdx + 1, uuids);
        },
        leaveDragFromActivity(event) {
            var target = $(event.target).closest('.activityTable')[0];
            if ($(target).hasClass('activityTable')) {
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
            }
        },
        allowDropToActivity(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
            //window.requestAnimationFrame(function(){
            //qgroup to qgroup sorting.
            if ($(this.currentDraggedItem).hasClass('activityTable')) {
                var target = $(event.target).closest('.activityTable')[0];
                if (!target) {
                    return;
                }
                if (!target.isSameNode(this.currentDraggedItem)) {
                    var bounding = target.getBoundingClientRect();
                    var offset = bounding.y + bounding.height / 2;
                    if (event.clientY - offset > 0) {
                        $(target).removeClass('selected-top');
                        $(target).addClass('selected-bottom');
                    } else {
                        $(target).removeClass('selected-bottom');
                        $(target).addClass('selected-top');
                    }
                }
            }

            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };

            this.stopScroll = true;
            if (event.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (event.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        dragActivity(event, moduleIdx, activityIdx) {
            if (this.currentDraggedItem != null) {
                return;
            }
            event.dataTransfer.setData(
                'text',
                JSON.stringify({
                    is: 'activity',
                    uuid: this.modules[moduleIdx].activities[activityIdx].uuid,
                    moduleIdx: moduleIdx,
                    activityIdx: activityIdx,
                })
            );
            event.dataTransfer.dropEffect = 'move';
            this.currentDraggedItem = event.target;
        },
        syncCourseObject(data) {
            this.course = data;

            Events.fire('topbar_update', {
                breadcrumb: [
                    { name: 'Home' },
                    { name: 'Courses', link: 'courses.index' },
                    { name: this.course.name, link: 'courses.view', params: { id: this.course.uuid } },
                ],
            });
        },
        toggleModules() {
            this.selectedModules = [];
            if (this.selectAllModules) {
                for (var i = 0; i < this.modules.length; i++) {
                    this.selectedModules.push(this.modules[i].uuid);
                }
            }
        },
        searchModule() {
            Events.fire('modules_listing_refresh');
        },
        downloadReport(report) {
            axios({
                url: `courses/${this.course.uuid}/downloadables/${report.id}`,
                method: 'POST',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', report.filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        processSelfSignIn() {
            var that = this;
            axios
                .post('/courses/' + this.course.uuid + '/self-signin')
                .then(function (response) {
                    that.course.isSelfSignIn = !that.course.isSelfSignIn;
                })
                .catch(function (errors) {});
        },
        updateIsSelfSignIn() {
            var that = this;
            axios
                .post('/courses/' + this.course.uuid + '/express-sign-in', {
                    isSelfSignIn: this.form.model.isSelfSignIn,
                })
                .then(function (response) {
                    that.course.isSelfSignIn = that.form.model.isSelfSignIn;
                })
                .catch(function (errors) {});
        },
        updateOthers() {
            var that = this;

            if (this.form.model.anonymous) {
                this.form.model.allowedDomains = '';
                this.form.model.ssoGoogle = false;
                this.form.model.ssoMicrosoft = false;
            }
            if (!this.form.model.ssoGoogle) {
                this.form.model.ssoGoogleAllowedDomains = '';
            }
            if (!this.form.model.ssoMicrosoft) {
                this.form.model.ssoMicrosoftAllowedDomains = '';
            }
            let allowedDomains = [];
            if (this.form.model.allowedDomains != null && this.form.model.allowedDomains.trim().length !== 0) {
                allowedDomains = this.form.model.allowedDomains.split(',');
            }
            let ssoGoogleAllowedDomains = [];
            if (this.form.model.ssoGoogleAllowedDomains != null && this.form.model.ssoGoogleAllowedDomains.trim().length !== 0) {
                ssoGoogleAllowedDomains = this.form.model.ssoGoogleAllowedDomains.split(',');
            }
            let ssoMicrosoftAllowedDomains = [];
            if (this.form.model.ssoMicrosoftAllowedDomains != null && this.form.model.ssoMicrosoftAllowedDomains.trim().length !== 0) {
                ssoMicrosoftAllowedDomains = this.form.model.ssoMicrosoftAllowedDomains.split(',');
            }

            if (!this.form.model.anonymous) {
                this.form.model.isSecretPin = false;
            }
            if (!this.form.model.isSecretPin) {
                this.form.model.secretPin = '';
            }
            if (_.isEqual(this.form.model, this.latestModelCopy)) {
                return;
            } else {
                this.latestModelCopy = _.cloneDeep(this.form.model);
            }
            axios
                .patch('/courses/' + this.course.uuid + '/express-sign-in', {
                    anonymous: this.form.model.anonymous,
                    allowedDomains: allowedDomains,
                    secretPin: this.form.model.secretPin,
                    ssoGoogle: this.form.model.ssoGoogle,
                    ssoGoogleAllowedDomains: ssoGoogleAllowedDomains,
                    ssoMicrosoft: this.form.model.ssoMicrosoft,
                    ssoMicrosoftAllowedDomains: ssoMicrosoftAllowedDomains,
                })
                .then(function (response) {
                    that.course.anonymous = that.form.model.isSelfSignIn;
                    that.course.allowedDomains = that.form.model.allowedDomains;
                    that.course.secretPin = that.form.model.secretPin;
                    that.course.ssoGoogle = that.form.model.ssoGoogle;
                    that.course.ssoGoogleAllowedDomains = that.form.model.ssoGoogleAllowedDomains;
                    that.course.ssoMicrosoftAllowedDomains = that.form.model.ssoMicrosoftAllowedDomains;
                })
                .catch(function (errors) {});
        },
        activitiesCount(module) {
            let activities = _.get(module, 'activities', []);
            if (activities.length == 0) {
                return 0;
            }
            let c = 0;
            for (var i = 0; i < activities.length; i++) {
                let tests = _.get(activities[i], 'tests', []);
                if (tests.length != 0 || !activities[i].isPublished) {
                    c++;
                }
            }
            return c;
        },
        hasStudents(activity) {
            for (var i = 0; i < activity.tests.length; i++) {
                if (activity.tests[i].students.length != 0) {
                    return true;
                }
            }
            return false;
        },
        canEditActivity(activity) {
            if (!this.can('manage_modules_and_activities', 'Course-' + this.course.uuid) || activity.isPublished) {
                return false;
            }
            return true;
        },
        canGoToDashboard() {
            if (this.ability?.relevantRuleFor('get_role', 'Course-' + this.course.uuid)?.conditions?.role == 'Syllabus Builder') {
                return false;
            }
            return true;
        },
        canResetActivity(activity, test) {
            if (!['not started', 'draft', 'scheduled'].includes(test.status)) {
                if (!this.can('reset_activity', 'Course-' + this.course.uuid)) {
                    return false;
                }
            } else {
                if (!this.can('manage_test', 'Course-' + this.course.uuid)) {
                    return false;
                }
            }

            return true;
        },
        canDeleteActivity(activity, test) {
            if (activity.isPublished) {
                if (!['not started', 'draft', 'scheduled'].includes(test.status)) {
                    if (!this.can('delete_activity', 'Course-' + this.course.uuid)) {
                        return false;
                    }
                } else {
                    if (!this.can('manage_modules_and_activities', 'Course-' + this.course.uuid)) {
                        return false;
                    }
                }
            }

            if (!activity.isPublished) {
                if (!this.can('manage_modules_and_activities', 'Course-' + this.course.uuid)) {
                    return false;
                }
            }

            for (var i = 0; i < activity.tests.length; i++) {
                if (!['not started', 'draft', 'scheduled', 'ended', 'completed'].includes(activity.tests[i].status)) {
                    return false;
                }
            }
            return true;
        },
        processRetrieveModulesData(data) {
            var that = this;
            this.courseList = data.meta.courses;
            this.sectionList = data.meta.sections;
            if (!this.query_fields.sectionUuid) {
                this.query_fields.sectionUuid = this.sectionList.length != 0 ? this.sectionList[0].uuid : '';
            }

            this.moduleSearchData = data;
            let hidden_modules = [];
            let courses = this.store.courses;
            let idx = _.findIndex(courses, function (o) {
                return o.uuid == that.course.uuid;
            });
            if (idx != -1) {
                let sections = courses[idx].sections;
                let idx2 = _.findIndex(sections, function (o) {
                    return o.uuid == that.query_fields.sectionUuid;
                });
                if (idx2 != -1) {
                    hidden_modules = sections[idx2].hidden_modules;
                }
            }
            for (var i = 0; i < data.data.data.data.length; i++) {
                let tempData = data.data.data.data[i];
                let idx = _.findIndex(this.modules, function (o) {
                    return o.uuid == tempData.uuid;
                });
                if (idx == -1) {
                    if (hidden_modules.includes(tempData.uuid)) {
                        tempData._display = false;
                    } else {
                        tempData._display = true;
                    }
                    this.modules.push(tempData);
                } else {
                    this.modules[idx] = tempData;
                    if (hidden_modules.includes(this.modules[idx].uuid)) {
                        this.modules[idx]._display = false;
                    } else {
                        this.modules[idx]._display = true;
                    }
                }
            }
            for (var i = this.modules.length - 1; i >= 0; i--) {
                let a = this.modules[i];
                let idx = _.findIndex(data.data.data.data, function (o) {
                    return o.uuid == a.uuid;
                });
                if (idx == -1) {
                    this.modules.splice(i, 1);
                }
            }
            for (var i = 0; i < this.modules.length; i++) {
                let a = this.modules[i];
                for (var j = a.activities.length - 1; j > -1; j--) {
                    if (a.activities[j].tests.length === 0 && a.activities[j].isPublished) {
                        a.activities.splice(j, 1);
                    }
                }
            }
            let postfix = 'd'; //desktop
            if ($(window).width() < 992) {
                postfix = 'm';
            }
            let activityUuid = _.get(this.$route, 'query.activityUuid', false);
            if (activityUuid) {
                Vue.nextTick(function () {
                    $('#' + activityUuid + '-' + postfix).focus();
                });
            }
            let moduleUuid = _.get(this.$route, 'query.moduleUuid', false);
            if (moduleUuid) {
                Vue.nextTick(function () {
                    $('#' + moduleUuid).focus();
                });
            }
        },
        onCopyClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Copied to clipboard',
            });
        },
        onErrorClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Error copying to clipboard',
            });
        },
        getActivityModality(activity) {
            return _.get(activity, 'settings.' + activity.type + '.type', '');
        },
        getTestModality(test) {
            return _.get(test, 'settings.type', '');
        },
        fetch() {
            let that = this;
            axios.get('/courses/' + this.$route.params.id).then(function (response) {
                that.course = response.data.data;
                let replaceValues = (str, params) => str.replace(/(^|\/):(\w+)(?=\/|$)/g, (m, g1, g2) => g1 + (params[g2] || m));
                that.EXPRESS_SIGN_IN_URL = replaceValues(that.EXPRESS_SIGN_IN_URL, { courseID: that.course.urlCode });
                //Vue.set(that.course,'allowSelfSignIn',false);
                /*for(var i = 0 ;i < that.course.modules.length;i++){
     					let mod = that.course.modules[i];
     					mod.activities = _.filter(that.course.activities,function(o){
     						return o.modules_ref[0]==mod.id;
     					});
     				}*/

                that.breadCrumbTitle(that.currentTab);

                that.component_done_loading = true;

                that.form.setModel(that.course);

                that.form.model.isSecretPin = that.course.secretPin ? true : false;

                if (that.course.allowedDomains != null) {
                    that.form.model.allowedDomains = that.form.model.allowedDomains.toString();
                }

                if (that.course.ssoGoogleAllowedDomains != null) {
                    that.form.model.ssoGoogleAllowedDomains = that.form.model.ssoGoogleAllowedDomains.toString();
                }

                if (that.course.ssoMicrosoftAllowedDomains != null) {
                    that.form.model.ssoMicrosoftAllowedDomains = that.form.model.ssoMicrosoftAllowedDomains.toString();
                }
            });
        },
        init() {
            this.sortList();
        },
        sortList(moduleIdx) {
            this.modules[moduleIdx].activities = _.orderBy(this.modules[moduleIdx].activities, [this.sort], [this.order]);
        },
        toggle(column = '', moduleIdx) {
            if (this.sort == column) {
                if (this.order == 'asc') {
                    this.order = 'desc';
                } else {
                    this.order = 'asc';
                }
            } else {
                this.order = 'asc';
                this.sort = column;
            }
            this.sortList(moduleIdx);
        },
        canDisplayExportGradesButton(activity, test) {
            if (!this.can('export_grades', 'Course-' + this.course.uuid)) {
                return false;
            }

            if (!['completed', 'ended'].includes(test.status) || test.type == 'prework') {
                return false;
            }

            if (test.type == 'application' && !_.get(activity, 'settings.application.isApplicationGraded')) {
                return false;
            }
            return true;
        },
        lookForValue(newValue) {
            return (this.updateManageValue = newValue);
        },
        getBackToModule() {
            return (this.tab = 'module');
        },
        storeTab(tab) {
            this.tab = tab;
            this.$router.replace({ query: { ...this.$route.query, tab } });

            document.title = `${tab} | ${this.course.name} | InteDashboard | TBL Makes Teams Work`;

            Events.fire('topbar_update', {
                breadcrumb: [
                    { name: 'Home' },
                    { name: 'Courses', link: 'courses.index' },
                    { name: this.course.name, link: 'courses.view', params: { id: this.course.uuid } },
                ],
            });
        },
        moduleRefresh() {
            Events.fire('modules_listing_refresh');
        },
        canDuplicateModule() {
            return (
                this.can('manage_modules_and_activities', 'Course-' + this.course.uuid) ||
                this.can('duplicate_modules_and_activities_to_another_course', 'Course-' + this.course.uuid)
            );
        },
        generateDuplicateToolTip(module) {
            if (
                !this.can('manage_modules_and_activities', 'Course-' + this.course.uuid) &&
                !this.can('duplicate_modules_and_activities_to_another_course', 'Course-' + this.course.uuid)
            ) {
                return 'You do not have permission to duplicate modules';
            }

            return `Duplicate ${module.name} module`;
        },
        generateMoreActionsButtonTooltip() {
            if (
                !this.can('manage_modules_and_activities', 'Course-' + this.course.uuid) &&
                !this.can('duplicate_modules_and_activities_to_another_course', 'Course-' + this.course.uuid)
            ) {
                return 'You do not have permission to duplicate modules';
            }

            if (this.selectedModules.length === 0) {
                return 'You need to select at least 1 module to enable the button';
            }

            return '';
        },
        canArchiveModule(module) {
            if (this.can('manage_modules_and_activities', 'Course-' + this.course.uuid)) {
                if (this.ability?.relevantRuleFor('get_role', 'Course-' + this.course.uuid)?.conditions?.role == 'Syllabus Builder') {
                    if (module.activities.some((activity) => activity.isPublished)) {
                        return false;
                    }
                }

                return true;
            }

            return false;
        },
        generateArchiveToolTip(module) {
            if (this.can('manage_modules_and_activities', 'Course-' + this.course.uuid)) {
                if (this.ability?.relevantRuleFor('get_role', 'Course-' + this.course.uuid)?.conditions?.role == 'Syllabus Builder') {
                    if (module.activities.some((activity) => activity.isPublished)) {
                        return 'You cannot archive a module with published activities';
                    }
                }

                return `Archive ${module.name} module`;
            }

            return 'You do not have permission to archive modules';
        },
        tabStickyTop() {
            const topOffset =
                $(window).width() < 992
                    ? $('.fixed-banner-container').outerHeight() + $('.top_nav.mobile').outerHeight()
                    : $('.fixed-banner-container').outerHeight() + $('.top_nav.desktop').outerHeight();

            this.$refs.tabsSticky.style.top = `${topOffset}px`;
            this.$refs.tabsSticky.style.paddingTop = '10px';
        },
        breadCrumbTitle(tab) {
            var tabName =
                tab == 'module'
                    ? 'Module & Activities'
                    : tab == 'student'
                    ? 'Students'
                    : tab == 'downloadables'
                    ? 'Grades'
                    : tab == 'overview'
                    ? 'Settings'
                    : tab == 'collaborators'
                    ? 'Collaborators'
                    : '';

            document.title = `${tabName} | Course | InteDashboard | TBL Makes Teams Work`;

            Events.fire('topbar_update', {
                breadcrumb: [
                    { name: 'Home' },
                    { name: 'Courses', link: 'courses.index' },
                    {
                        name: this.course.name + (this.course.code ? ' (' + this.course.code + ')' : ''),
                    },
                    {
                        name: tabName,
                    },
                ],
            });
        },
    },
};
</script>
<style scoped lang="scss">
.card-container {
    padding: 0 20px;
}

.card-body {
    padding-bottom: 20px;
}

.card-container .btn {
    margin-left: 0;
}

.card-body > .col-xs-12:last-child > .form-control-static:last-child {
    margin-bottom: 0;
}

.card-container .mainModulesBtn,
.card-container .mainActivityBtn {
    margin-bottom: 20px;
}

.subTable {
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }

    th {
        position: relative;

        button {
            right: 20px;
            padding: 0;
            font-size: 0.6rem;
            border-radius: 2px;

            .fa.fa-sort {
                margin-left: 0;
            }
        }
    }
}
.btn-grey {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background: #fff;
    color: #717171;
    display: inline-block;
    padding: 6px 12px;
    font-size: 0.875em;
    line-height: 1.2;
}

.subTable .fa-ellipsis-v {
    color: #717171;
    font-size: 0.75em;
}

.blueTitle {
    margin-left: 20px;
    color: #0071be;
    display: inline-block;
    font-size: 0.875em;
    text-transform: capitalize;
}

.activityHeaders th {
    padding-top: 6px;
    padding-bottom: 4px;
    font-size: 0.7rem;
    color: #717171;

    i {
        color: #717171;
    }
}

.subContentDetail {
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    margin-bottom: 20px;
}

.subContentDetail:last-child {
    margin: 0;
}

.subContentDetail .header {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.subContentHeader {
    padding: 20px;
    font-weight: bold;
    background: #fbfbfb;
    border-bottom: 1px solid #d8d8d8;
    text-transform: uppercase;
}

.subContentDetails {
    padding: 20px;
}

.table .table.marginBottom20 {
    margin-bottom: 20px;
}
.subContent {
    padding: 0;
    border: 0;
    background: none;
}

.table .subContentTable .table {
    margin-bottom: 20px;
}

.subContentTable:last-child .table {
    margin-bottom: 0;
}

.table .subContentTable tbody .table {
    margin-bottom: 0;
}

ul.nav-pills {
    width: 100%;
    display: flex;
    justify-content: center;
}

ul.nav-pills li {
    background-color: #f2f2f2;
    border: solid 1px #d8d8d8;
    margin-left: 0px;
    border-radius: 4px;
}

ul.nav-pills li a {
    padding: 6px 15px 6px;
}

ul.nav-pills > li:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

ul.nav-pills > li:not(:first-child):not(:last-child) {
    border-radius: 0;
}

ul.nav-pills > li:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

ul.nav-pills > li.active {
    border: solid 1px #0071be;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
}

ul.nav-pills > li.active,
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: black;
    background-color: white;
}

.new-panel {
    .x_content {
        .x_side_bar {
            width: 10%;
            height: auto;
            background: #eff2f6;
            border-right: 1px solid #ddd;
            padding: 10px;

            label {
                padding: 4px 9px;
            }

            .list-group {
                margin-bottom: 0;

                .list-group-item {
                    padding: 8px;
                    line-height: 0.8;
                    background: none;
                    border: none;
                    border-left: 2px solid transparent;
                    color: #333;

                    &:first-child,
                    &:last-child {
                        border-radius: 0;
                    }

                    &.active,
                    &:hover,
                    &:active,
                    &:focus {
                        border-left: 2px solid #ffffff;
                        background: #495060;
                        color: #ffffff;
                    }
                }
            }
        }

        .x_with_side_bar {
            width: calc(100% - 10%);
            padding: 1.5rem;

            &.col-12 {
                width: 100%;
            }
        }
    }
}
.activityTable {
    border-top: 0;
}

.moduleActionHeader {
    width: 70px;
}

.modal-body {
    .row {
        &:not(:first-child) {
            margin-top: 20px;
        }

        .col-md-6 {
            padding-right: 10px;

            &:last-child {
                padding-right: 0;
                padding-left: 10px;
            }
        }
    }
}
.enrollmentSetting {
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 100%;

        & > div:last-child {
            width: calc(100% - 70px);
        }
    }

    &:not(first-child) {
        margin-top: 20px;
    }

    label {
        font-size: 24px;
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 8px;
    }
}
</style>
