<template>
    <div>
        <div class="col-xs-12 right-container">
            <div class="tabs-sticky marginBottom30">
                <nav class="links margin0">
                    <ul>
                        <li v-for="tab in tabs" :key="tab" :class="{ active: currentTab == tab }" @click.prevent="storeTab(tab)">
                            <a class="nav-link" data-toggle="tab" :href="`#${tab}`" :aria-controls="tab" :aria-selected="currentTab == tab">
                                {{ tab }}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="pageTitle">
                <h1>{{ currentTab }}</h1>
                <p class="col-md-6">
                    <template v-if="currentTab == 'My Courses'">
                        Find all the courses you own and collaborate on. Easily manage and edit your courses based on your collaborator permissions.
                    </template>
                    <template v-else>
                        Explore all courses available within your organization in one centralized directory. Browse through the offerings,
                        <a href="#" target="newTab"> <i class="fas fa-info-circle" aria-hidden="true" /> request access</a>, or
                        <a href="#" target="newTab">
                            <i class="fas fa-info-circle" aria-hidden="true" />
                            join courses
                        </a>
                        to expand your learning and collaboration. Stay connected with the knowledge and resources shared across your organization.
                    </template>
                </p>
            </div>

            <iframe
                v-if="auth.user().roleId == 2 && ['c79e896b-cd8e-46a5-acd6-626d454063a3'].includes(auth.user().account.uuid)"
                id="retool-iframe"
                title="Retool Iframe"
                width="100%"
                height="500px"
                src="https://modernreportcard.retool.com/embedded/public/2f4c19cc-18e4-4b20-b8b8-a14100cd0a10/page1"
            >
            </iframe>

            <kr-search
                name="courses_listing"
                :url="{ url: '/courses' }"
                :columns="{
                    action: 'display:Actions|sortable:false|width:90px',
                    name: 'display:Name|sortable:true|width:calc(100% - 142px - 142px - 142px - 142px - 165px - 90px)',
                    code: 'display:Code|sortable:true|mobile:hide|width:142px',
                    startsFrom: 'display:Starts Form|sortable:true|src:startDate|width:142px|mobile:hide',
                    endsOn: 'display:Ends On|sortable:true|src:endDate|width:142px|mobile:hide',
                    owner: 'display:Owner|sortable:false|mobile:hide|width:142px',
                    myRole: 'display:My Role|sortable:false|mobile:hide|width:170px',
                }"
                :options="{
                    archived_field: true,
                    drop_page_number: false,
                    action_column: false,
                    default_query_fields: searchData.query,
                    newServerMode: {
                        includes: ['q', 'sort', 'order', 'isArchived', 'name', 'code', 'startDate', 'endDate', 'owner', 'currentUserRole'],
                    },
                    pageName: 'courses',
                    allCourses: currentTab == 'All Courses',
                    table_caption: true,
                }"
                class="overflowXinherit"
                @retrieve-data="getSearchData"
            >
                <template #upper-right>
                    <router-link v-slot="{ navigate }" :to="{ name: 'courses.create' }" custom>
                        <button class="btn btn-success" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                            <i class="fa fa-plus marginRight8" aria-label="Add" /> New Course
                        </button>
                    </router-link>
                </template>

                <template #table-title="props">
                    <div>
                        <template v-if="props.model.meta.paginator.total > 0">
                            Showing {{ props.model.from }} - {{ props.model.to }} of
                            {{ props.model.meta.paginator.total }}
                        </template>
                    </div>
                </template>

                <template #bottom-display-from-to="props">
                    <div v-if="props.model.meta.paginator.total > 0" class="displayNo">
                        Showing {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </div>
                </template>

                <template #table-caption> List of {{ currentTab }} </template>

                <template #ndata>
                    <tr>
                        <td class="borderNone">
                            No courses yet,
                            <router-link
                                v-if="!auth.isImpersonation()"
                                :to="{ name: 'courses.create' }"
                                tag="a"
                                class="link"
                                aria-labelledby="Create a course"
                                aria-label="Click here to create one"
                            >
                                click here
                            </router-link>
                            to create one.
                        </td>
                    </tr>
                </template>

                <template #cdata="props">
                    <td class="py-9px">
                        <div class="dropdown">
                            <button
                                :id="`dropdownMenuButton-${props.model.uuid}`"
                                v-tooltip="'More Actions'"
                                class="btn btn-outline-default icon-only"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-label="More Actions"
                                @click="dropdownToggle"
                            >
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                            <div
                                :id="`dropdown-${props.model.uuid}`"
                                class="dropdown-menu dropdown-menu-left dropdown-menu-new"
                                :aria-labelledby="`dropdownMenuButton-${props.model.uuid}`"
                            >
                                <ul>
                                    <template v-if="!props.model.currentUserRole">
                                        <li v-if="props.model.autoJoin.allow || auth.user().role == 'Super Admin'">
                                            <a class="primary-state" href="#" @click.prevent="$refs['JoinCourseComponent'].join(props.model, auth.user().role)">
                                                <span> <i class="fa-solid fa-right-to-bracket marginRight8" aria-hidden="true"></i> Join Course </span>
                                            </a>
                                        </li>

                                        <li v-else>
                                            <a
                                                class="primary-state"
                                                href="#"
                                                :aria-labelledby="'Download ' + props.model.name + 'Report'"
                                                aria-label="Generate Course Grades Report"
                                                @click.prevent="$refs['RequestAccessComponent'].request(props.model)"
                                            >
                                                <span> <span class="material-symbols-outlined"> exit_to_app </span>Request Access</span>
                                            </a>
                                        </li>
                                    </template>

                                    <template v-else>
                                        <li>
                                            <router-link
                                                class="primary-state"
                                                :to="{
                                                    name: 'courses.view',
                                                    params: {
                                                        id: props.model.uuid,
                                                    },
                                                }"
                                                :aria-labelledby="'View ' + props.model.name"
                                            >
                                                <span> <i class="fas fa-eye marginRight8" aria-hidden="true" />View Course </span>
                                            </router-link>
                                        </li>

                                        <li v-if="canExportGrades(props.model.uuid)">
                                            <a
                                                class="primary-state"
                                                href="#"
                                                :aria-labelledby="'Download ' + props.model.name + 'Report'"
                                                aria-label="Generate Course Grades Report"
                                                @click.prevent="$refs['GenerateGradesComponent'].generate(props.model)"
                                            >
                                                <span> <i class="fa fa-download marginRight8" aria-hidden="true" />Export Grades </span>
                                            </a>
                                        </li>

                                        <li v-if="canDuplicateCourse(props.model.uuid)">
                                            <a
                                                class="primary-state"
                                                href="#"
                                                :aria-labelledby="'Duplicate ' + props.model.name"
                                                @click.prevent="$refs['DuplicateCourseComponent'].duplicate(props.model)"
                                            >
                                                <span> <i class="fa fa-files-o marginRight8" aria-hidden="true" />Duplicate Course </span>
                                            </a>
                                        </li>

                                        <hr v-if="canArchiveCourse(props.model.uuid)" />

                                        <li v-if="searchData.query.isArchived === '0' && canArchiveCourse(props.model.uuid)">
                                            <a class="danger-state" href="#" @click.prevent="$refs['ArchiveModalComponent'].archive(props.model)">
                                                <span> <i class="fa fa-archive marginRight8" aria-hidden="true" />Archive Course </span>
                                            </a>
                                        </li>

                                        <li v-if="searchData.query.isArchived === '1' && canArchiveCourse(props.model.uuid)">
                                            <a class="primary-state" href="#" @click.prevent="$refs['ReinstateModalComponent'].reinstate(props.model)">
                                                <span> <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate Course </span>
                                            </a>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </td>
                    <td>
                        <router-link
                            v-if="props.model.currentUserRole"
                            v-tooltip="{ content: 'View modules and activities page' }"
                            tag="a"
                            :to="{
                                name: 'courses.view',
                                params: { id: props.model.uuid },
                            }"
                            class="link"
                            :aria-label="props.model.name + ' click to view modules and activities page'"
                            v-dompurify-html="highlight(props.model.name)"
                        />
                        <template v-else>
                            <p v-dompurify-html="highlight(props.model.name)" />
                        </template>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <router-link
                            v-if="props.model.code && props.model.currentUserRole"
                            v-tooltip="{ content: 'View modules and activities page' }"
                            tag="a"
                            :to="{
                                name: 'courses.view',
                                params: { id: props.model.uuid },
                            }"
                            class="link"
                            :aria-label="'course code ' + props.model.code"
                            v-dompurify-html="highlight(props.model.code)"
                        />
                        <template v-else>
                            <template v-if="props.model.code">
                                <p v-dompurify-html="highlight(props.model.code)" />
                            </template>
                        </template>
                    </td>
                    <td class="hidden-xs hidden-sm" v-dompurify-html="parseReadableDate(props.model.startDate)" />
                    <td class="hidden-xs hidden-sm" v-dompurify-html="parseReadableDate(props.model.endDate)" />
                    <td class="hidden-xs hidden-sm whiteSpaceNowrap">
                        <p
                            v-if="props.model.owner"
                            v-dompurify-html="highlight(props.model.owner.length > 30 ? truncate(props.model.owner, 28) : props.model.owner)"
                        />
                        <template v-else> - </template>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <span
                            v-if="props.model.currentUserRole"
                            class="tableStatusTag whiteSpaceNowrap dynamic-status"
                            :style="{
                                '--main-color': $ability.relevantRuleFor('get_color', props.model.currentUserRole)?.conditions?.color,
                            }"
                        >
                            {{ props.model.currentUserRole }}
                        </span>
                        <template v-else> - </template>
                    </td>
                </template>
            </kr-search>
        </div>

        <duplicateCourseComponent ref="DuplicateCourseComponent" />
        <generateGradesComponent ref="GenerateGradesComponent" />
        <archiveModalComponent ref="ArchiveModalComponent" />
        <reinstateModalComponent ref="ReinstateModalComponent" />
        <joinCourseComponent ref="JoinCourseComponent" />
        <requestAccessComponent ref="RequestAccessComponent" />
    </div>
</template>
<script setup>
import KrAuth from '../../../components/auth/auth';
import KrForm from '../../../components/forms/form';

import duplicateCourseComponent from './actions/duplicateCourse.vue';
import generateGradesComponent from './actions/generateGrades.vue';
import archiveModalComponent from './actions/archiveModal.vue';
import reinstateModalComponent from './actions/reinstateModal.vue';
import joinCourseComponent from './actions/joinCourse.vue';
import requestAccessComponent from './actions/requestAccess.vue';

import { useAbility } from '@casl/vue';
import { ref, onMounted, nextTick, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const { can } = useAbility();
const auth = new KrAuth();
const searchData = ref({
    meta: { paginator: { total: 0 }, filters: {} },
    query: { isTrashed: '0', isArchived: '0', sort: 'startDate', order: 'desc' },
});
const tabs = ref(['My Courses', 'All Courses']);
const currentTab = ref(route.query.tab || 'My Courses');

const getSearchData = (data) => {
    searchData.value = data;
};

const highlight = (text) => {
    if (searchData.value.query.q != '' && text != null) {
        return text.replace(new RegExp(searchData.value.query.q, 'gi'), '<span class="table-keyword-highlight">$&</span>');
    } else {
        return text;
    }
};

const dropdownToggle = () => {
    if ($('table tbody tr').length >= 6) {
        $('table tbody tr:nth-last-child(-n+4) td:first-child div').addClass('dropup');
    } else if ($('table tbody tr').length == 5) {
        $('table tbody tr:nth-last-child(-n+2) td:first-child div').addClass('dropup');
    }
};

const truncate = (str, characters = 100) => {
    return _.truncate(str, {
        length: characters,
        omission: '',
    });
};

const searchCourses = () => {
    nextTick(() => {
        Events.fire('courses_listing_refresh');
    });
};

const canExportGrades = (courseUuid) => {
    return can('export_grades', 'Course-' + courseUuid);
};

const canDuplicateCourse = (courseUuid) => {
    return can('duplicate_modules_and_activities_to_another_course', 'Course-' + courseUuid);
};

const canArchiveCourse = (courseUuid) => {
    return can('archive_course', 'Course-' + courseUuid);
};

const storeTab = (tab) => {
    currentTab.value = tab;
    searchData.value.query.q = '';
    searchData.value.query.isArchived = '0';
    searchData.value.query.page = 1;
    router.replace({ query: { ...route.query, tab } });
    searchCourses();

    router.replace({ query: { ...route.query, tab } }).then(() => {
        document.title = `${tab} | InteDashboard | TBL Makes Teams Work`;

        Events.fire('topbar_update', {
            breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }],
        });
    });
};

onMounted(() => {
    Events.fire('topbar_update', {
        breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }],
    });
});
</script>
