<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="pageTitle">
            <h1>Add Collaborator</h1>
            <p class="col-md-6">
                Add collaborators to your course by selecting a staff member from your institution’s account, choosing their access level, and confirming. Once
                added, the collaborator will see the course in their Courses page.
            </p>
        </div>

        <kr-panel>
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>Add Collaborator</h2>
                </div>
            </template>
            <template #content>
                <h3>Add staff and select Course Collaborator Role</h3>
                <p>Search for users within your organization to add as a collaborator. You can search by name or email.</p>

                <div class="position-relative marginTop20">
                    <label for="courseAddTeacher" class="control-label"> Search Teacher </label>
                    <div class="form-group form-search has-feedback has-feedback-left marginBottom20">
                        <input
                            id="courseAddTeacher"
                            v-model="teacherSearchData.q"
                            type="text"
                            class="form-control"
                            placeholder="Search Staff name, email"
                            aria-label="Search Teacher"
                            @keyup="searchCourseDropdown"
                            @focus="showDropdown = true"
                            @blur="handleBlur"
                        />
                        <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                    </div>

                    <div v-if="showDropdown" class="searchDropdown" :class="{ 'searchDropdown--down': collaborators.length > 3 }">
                        <template v-if="unassignedStaffs.length == 0">
                            <p class="paddingLeft10">No staff to add</p>
                        </template>
                        <template v-if="teacherSearchData.isSearching">
                            <template v-if="resultQuery.length == 0">
                                <p class="paddingLeft10">Search not found</p>
                            </template>
                            <ul v-else role="listbox" aria-label="Search results">
                                <li
                                    v-for="user in resultQuery"
                                    :key="`${user.uuid}-teacherSearchData`"
                                    :id="`optionTeacherSearchData-${user.uuid}`"
                                    role="option"
                                    class="search-result-item"
                                >
                                    <button class="btn flexInherit margin0" @click.prevent="selectUser(user)">
                                        <span>{{ user.displayName }}</span>
                                        <br />
                                        <span class="fontGrey fs-16px">
                                            {{ user.email }}
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </template>
                        <ul v-else role="listbox" aria-label="Search results">
                            <li
                                v-for="user in unassignedStaffs"
                                :key="`${user.uuid}-notInTeacher`"
                                :id="`optionNotInTeacher-${user.uuid}`"
                                role="option"
                                class="search-result-item"
                            >
                                <button class="btn flexInherit margin0" @click.prevent="selectUser(user)">
                                    <span>{{ user.displayName }}</span>
                                    <br />
                                    <span class="fontGrey fs-16px">
                                        {{ user.email }}
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div v-if="collaborators.length > 0" class="table-responsive overflowAuto">
                    <table class="table">
                        <caption>
                            Selected Staffs ({{
                                collaborators.length
                            }})
                        </caption>
                        <thead>
                            <tr class="blueHeader">
                                <th>Name</th>
                                <th class="minWidth300px width300px maxWidth300px">Course Collaborator Role</th>
                                <th class="minWidth195px width195px maxWidth195px">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="collaborator in collaborators" :key="collaborator.userUuid">
                                <td>
                                    {{ collaborator.displayName }}
                                    <br />
                                    <span class="fontGrey fs-16px">
                                        {{ collaborator.email }}
                                    </span>
                                </td>
                                <td>
                                    <div class="form-group-select-wrapper form-dropdown">
                                        <select
                                            :id="`select-course-collaborator-role-${collaborator.userUuid}`"
                                            v-model="collaborator.roleId"
                                            class="form-control"
                                        >
                                            <option value="" disabled selected>Course Collaborator Role</option>
                                            <option v-for="roleOption in roles" :key="roleOption" :value="roleOption.id">
                                                {{ roleOption.name }}
                                            </option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger" @click="removeUser(collaborator)">
                                        <span class="material-symbols-outlined filled marginRight8" aria-hidden="true"> person_remove </span>
                                        Remove Staff
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-xs-12 col-md-6 flex gap10 marginTop20">
                    <button class="btn btn-outline-default flexInherit margin0" @click="$router.back()">Cancel</button>
                    <button
                        v-tooltip="!canSave ? 'Please ensure you have assign role for all staff' : ''"
                        class="btn btn-success flexInherit margin0"
                        :class="{ 'disabled hasToolTip': !canSave }"
                        @click="submit"
                    >
                        Save
                    </button>
                </div>
            </template>
        </kr-panel>
    </div>
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router';
import { ref, onMounted, computed } from 'vue';
import { notify } from '@kyvg/vue3-notification';

const route = useRoute();
const router = useRouter();
const course = ref({
    uuid: route.params.id,
    name: route.query.courseName || 'Course',
});
const component_done_loading = ref(false);
const unassignedStaffs = ref(JSON.parse(route.query.listOfUnassignedStaff));
const teacherSearchData = ref({
    meta: { paginator: { total: 0 }, filters: {} },
    query: {},
});

const resultQuery = ref([]);
const collaborators = ref([]);
const showDropdown = ref(false);
const roles = ref([]);

document.title = `Add Collaborator | ${course.value.name} | InteDashboard | TBL Makes Teams Work`;
Events.fire('topbar_update', {
    breadcrumb: [
        { name: 'Home' },
        { name: 'Courses', link: 'courses.index' },
        {
            name: course.value.name + (course.value.code ? ' (' + course.value.code + ')' : ''),
            link: 'courses.view',
            params: { id: route.params.id },
        },
        {
            name: 'Collaborators',
            link: 'courses.view',
            params: { id: route.params.id },
            query: { tab: 'collaborators' },
        },
        {
            name: 'Add Collaborator',
        },
    ],
});

const searchCourseDropdown = () => {
    resultQuery.value = [];

    if (teacherSearchData.value.q.match(/[a-z]/i) != null) {
        teacherSearchData.value.isSearching = true;
        teacherSearchData.value.matched = 0;

        const searchTerm = teacherSearchData.value.q.toLowerCase();

        for (let i = 0; i < unassignedStaffs.value.length; i++) {
            const teacher = unassignedStaffs.value[i];
            const displayName = teacher.displayName.toLowerCase();
            const email = teacher.email.toLowerCase();

            if (displayName.includes(searchTerm) || email.includes(searchTerm)) {
                teacher._searchDisplay = true;
                teacherSearchData.value.matched++;
                unassignedStaffs.value[i]._matched++;
                resultQuery.value.push(teacher);
            } else {
                teacher._searchDisplay = false;
            }
        }
    } else {
        teacherSearchData.value.isSearching = false;
    }
};

const selectUser = (user) => {
    if (!collaborators.value.find((c) => c.userUuid === user.uuid)) {
        collaborators.value.push({
            userUuid: user.uuid,
            roleId: '',
            displayName: user.displayName,
            email: user.email,
        });
        teacherSearchData.value.q = '';
        searchCourseDropdown();
        showDropdown.value = false;
    }
};

const removeUser = (user) => {
    collaborators.value = collaborators.value.filter((c) => c.userUuid !== user.userUuid);
    notify({
        group: 'form',
        type: 'success',
        title: 'Success',
        text: 'Successfully removed selected staff',
    });
};

const handleBlur = () => {
    setTimeout(() => {
        showDropdown.value = false;
    }, 200);
};

const fetchRoles = () => {
    axios.get('course/roles').then((response) => {
        roles.value = response.data.data.filter((role) => role.name !== 'Course Owner');
    });
};

const canSave = computed(() => {
    return collaborators.value.length > 0 && collaborators.value.every((collab) => collab.roleId !== '');
});

const submit = () => {
    axios
        .post(`/courses/${route.params.id}/collaborators`, {
            collaborators: collaborators.value,
        })
        .then((response) => {
            collaborators.value = [];
            router.push({
                name: 'courses.view',
                params: { id: route.params.id },
                query: { tab: 'collaborators' },
            });
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Successfully added collaborators',
            });
        })
        .catch((error) => {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Failed to add collaborators',
            });
        });
};

onMounted(() => {
    fetchRoles();
    component_done_loading.value = true;
});
</script>
