<template>
    <div>
        <slot name="listing-top">
            <div v-if="has_table_tabs" class="navigation-tab">
                <slot name="tabs" />
            </div>
            <div v-if="has_top_bar" class="topBar">
                <div v-if="has_thead_title" class="md-upper">
                    <!-- <slot name="upper-left" :model="{ meta: meta, from: from, to: to }">
          </slot> -->
                    <slot name="table-title" :model="{ meta: meta, from: from, to: to }">
                        <template v-if="is_lti3_setting"> All Platforms ( </template>
                        <template v-else> All ( </template>
                        <div v-if="has_page_number" class="hidden-xs hidden-sm flexSpaceBetween align-items inlineFlex">
                            <div v-if="has_drop_page_number" class="floatLeft marginRight20">
                                <slot name="top-display-dropdown">
                                    <div class="form-group displayContent">
                                        <span>
                                            <label class="col-form-label"> Display </label>
                                            <label id="desktop_selectPlatForm" class="visible-hidden" style="visibility: hidden">
                                                All Platforms, Display
                                                {{ query.per_page }} results
                                            </label>
                                            <div class="form-dropdown digit">
                                                <select
                                                    v-model="query.per_page"
                                                    class="form-control"
                                                    aria-labelledby="desktop_selectPlatForm"
                                                    @change="debounced_search(true)"
                                                >
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                </select>
                                            </div>
                                        </span>
                                    </div>
                                </slot>
                            </div>
                            <div class="floatRight">
                                <slot name="top-display-from-to" :model="{ meta: meta, from: from, to: to }">
                                    <div class="displayNo">
                                        <p>
                                            {{ from }} - {{ to }} of
                                            {{ meta.paginator.total }}
                                        </p>
                                    </div>
                                </slot>
                            </div>
                            <div style="clear: both" />
                        </div>
                        <div v-if="has_page_number" class="hidden-md hidden-lg inlineFlex align-items">
                            <div v-if="has_drop_page_number" class="marginRight20">
                                <slot name="top-display-dropdown">
                                    <div class="form-group displayContent">
                                        <span>
                                            <label class="col-form-label"> Display </label>
                                            <label id="mobile_selectPlatForm" class="visible-hidden" style="visibility: hidden">
                                                All Platforms, Display
                                                {{ query.per_page }} results
                                            </label>
                                            <div class="form-dropdown digit">
                                                <select
                                                    v-model="query.per_page"
                                                    class="form-control"
                                                    aria-labelledby="mobile_selectPlatForm"
                                                    @change="debounced_search(true)"
                                                >
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                </select>
                                            </div>
                                        </span>
                                    </div>
                                </slot>
                            </div>
                            <div class="textAlignRight">
                                <slot name="top-display-from-to" :model="{ meta: meta, from: from, to: to }">
                                    <div class="displayNo">
                                        <p>
                                            {{ from }} - {{ to }} of
                                            {{ meta.paginator.total }}
                                        </p>
                                    </div>
                                </slot>
                            </div>
                        </div>
                        )
                    </slot>
                </div>
                <div class="md-lower">
                    <div v-if="has_search_field" class="marginLeft20">
                        <div class="col-xs-12 searchBox">
                            <div class="form-group form-search has-feedback has-feedback-left">
                                <input
                                    :id="name + '-id'"
                                    v-model="query.q"
                                    type="search"
                                    class="form-control"
                                    :placeholder="$t('global.tables.searchPlaceholderLabel')"
                                    :aria-label="'Search ' + query.pageName"
                                    @keyup="debounced_search(true)"
                                />
                                <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                            </div>
                        </div>
                    </div>

                    <slot name="lower-left">
                        <div class="flexOnly">
                            <div v-if="has_suspended_field" class="viewFilter marginLeft20">
                                <div class="form-group displayContent">
                                    <!-- <label class="col-form-label">VIEW</label> -->
                                    <div class="form-dropdown viewFilter marginLeft0">
                                        <select
                                            v-model="query.isSuspended"
                                            class="form-control"
                                            :aria-label="query.isSuspended == 0 ? 'Show Active ' + query.pageName : 'Show Suspended' + query.pageName"
                                            @change="debounced_search(true)"
                                        >
                                            <option value="0">{{ $t('global.tables.activeStatusLabel') }}</option>
                                            <option value="1">{{ $t('global.tables.suspendedStatusLabel') }}</option>
                                            <!-- <option value="*">All</option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="has_trash_field" class="viewFilter marginLeft20">
                                <div class="form-group displayContent">
                                    <!-- <label class="col-form-label">VIEW</label> -->
                                    <div class="form-dropdown viewFilter marginLeft0">
                                        <select
                                            v-model="query.isTrashed"
                                            class="form-control"
                                            :aria-label="query.isArchived == 0 ? 'Show Active ' + query.pageName : 'Show Archived' + query.pageName"
                                            @change="debounced_search(true)"
                                        >
                                            <option value="0">Active</option>
                                            <option value="1">Archived</option>
                                            <!-- <option value="*">All</option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="has_archived_field" class="viewFilter marginLeft20">
                                <div class="form-group displayContent">
                                    <!-- <label class="col-form-label">VIEW</label> -->
                                    <div class="form-dropdown viewFilter marginLeft0">
                                        <select
                                            v-model="query.isArchived"
                                            class="form-control"
                                            :aria-label="query.isArchived == 0 ? 'Show Active ' + query.pageName : 'Show Archived' + query.pageName"
                                            @change="debounced_search(true)"
                                        >
                                            <option value="0">Active</option>
                                            <option value="1">Archived</option>
                                            <!-- <option value="*">All</option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="has_at_risk_field" class="viewFilter marginLeft20">
                                <div class="form-group displayContent">
                                    <!-- <label class="col-form-label">VIEW</label> -->
                                    <div class="form-dropdown viewFilter marginLeft0">
                                        <select
                                            v-model="query.isAtRisk"
                                            class="form-control"
                                            :aria-label="query.isAtRisk == 0 ? 'At risk students' : 'Well performing students'"
                                            @change="debounced_search(true)"
                                        >
                                            <option value="0">At risk students</option>
                                            <option value="1">Well performing students</option>
                                            <!-- <option value="*">All</option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="has_pay_type_field" class="viewFilter marginLeft20">
                                <div class="form-group displayContent">
                                    <!-- <label class="col-form-label">VIEW</label> -->
                                    <div class="form-dropdown viewFilter marginLeft0">
                                        <select v-model="query.type" class="form-control" :aria-label="query.type" @change="debounced_search(true)">
                                            <option value="">{{ $t('global.tables.allPayTypeLabel') }}</option>
                                            <option value="Free">{{ $t('global.tables.freePayTypeLabel') }}</option>
                                            <option value="Free Trial">{{ $t('global.tables.freeTrialPayTypeLabel') }}</option>
                                            <option value="Paid Trial">{{ $t('global.tables.paidTrialPayTypeLabel') }}</option>
                                            <option value="Paid">{{ $t('global.tables.paidPayTypeLabel') }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="has_payment_method_field" class="viewFilter marginLeft20">
                                <div class="form-group displayContent">
                                    <!-- <label class="col-form-label">VIEW</label> -->
                                    <div class="form-dropdown viewFilter marginLeft0">
                                        <select
                                            v-model="query.paymentMethod"
                                            class="form-control"
                                            :aria-label="query.paymentMethod"
                                            @change="debounced_search(true)"
                                        >
                                            <option value="">{{ $t('global.tables.allPaymentMethodLabel') }}</option>
                                            <option value="Institution">{{ $t('global.tables.institutionPaymentMethodLabel') }}</option>
                                            <option value="Student-Paid">{{ $t('global.tables.studentPaidPaymentMethodLabel') }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="has_activated_field" class="viewFilter marginLeft20">
                                <div class="form-group displayContent">
                                    <!-- <label class="col-form-label">VIEW</label> -->
                                    <div class="form-dropdown viewFilter marginLeft0">
                                        <select v-model="query.status" class="form-control" :aria-label="query.status" @change="debounced_search(true)">
                                            <option value="">All</option>
                                            <option value="unactivated">Unactivated</option>
                                            <option value="activated">Activated</option>
                                            <option value="suspended">Suspended</option>
                                            <option value="deactivated">Deactivated</option>
                                            <option value="refunded">Refunded</option>
                                            <option value="expired">Expired</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <!---<div v-if="has_onboarding_field" class="viewFilter marginLeft20">
                                <div class="form-group displayContent">
                                    <div class="form-dropdown viewFilter marginLeft0">
                                        <select v-model="query.onboarding" class="form-control" :aria-label="query.onboarding" @change="debounced_search(true)">
                                            <option value="">{{ $t('global.tables.allOnboardingLabel') }}</option>
                                            <option value="N/A">{{ $t('global.tables.naOnboardingLabel') }}</option>
                                            <option value="To Onboard">{{ $t('global.tables.toOnboardOnboardingLabel') }}</option>
                                            <option value="Scheduled">{{ $t('global.tables.scheduledOnboardingLabel') }}</option>
                                            <option value="Done">{{ $t('global.tables.doneOnboardingLabel') }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>-->
                            <div v-if="has_permission_field" class="viewFilter marginLeft20">
                                <div class="form-group displayContent">
                                    <div class="form-dropdown viewFilter marginLeft0">
                                        <select v-model="query.type" class="form-control" :aria-label="query.type" @change="debounced_search(true)">
                                            <option value="*">All</option>
                                            <option value="Full Access">Full Access</option>
                                            <option value="Read Only">Read Only</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <button v-if="has_clear_field" class="btn btn-default" @click="clearQueryFields()">
                                <i class="fa fa-refresh marginRight8" aria-hidden="true" />{{ $t('global.tables.clearButtonLabel') }}
                            </button>

                            <div style="clear: both" />
                        </div>
                    </slot>

                    <div class="flexRight upper-right">
                        <slot name="upper-right"> </slot>
                    </div>
                </div>
                <slot name="lower-right" />
            </div>
        </slot>

        <slot name="content" :model="models">
            <div class="position-relative">
                <div v-if="!(meta.paginator.total == 0 && hide_table_if_empty) || !hide_table_if_empty" class="table-responsive">
                    <table
                        v-if="has_table"
                        class="table"
                        :style="[!has_thead ? { border: 0 } : {}, !has_table_background ? { background: 0 } : {}]"
                        style="box-shadow: none"
                    >
                        <caption v-if="has_table_caption">
                            <slot name="table-caption" :model="{ meta: meta, from: from, to: to }" />
                        </caption>
                        <thead v-show="has_thead">
                            <tr v-if="meta.paginator.total != 0">
                                <slot name="columns" props="cols">
                                    <template v-for="(column, idx) in cols">
                                        <th
                                            v-if="!column.slotted"
                                            :key="'desktop_slotted' + idx"
                                            :style="[
                                                column.width !== false ? { width: column.width } : {},
                                                column.maxWidth !== false
                                                    ? {
                                                          'max-width': column.maxWidth,
                                                      }
                                                    : {},
                                                column.minWidth !== false
                                                    ? {
                                                          'min-width': column.minWidth,
                                                      }
                                                    : {},
                                            ]"
                                            class="hidden-xs hidden-sm"
                                            scope="col"
                                        >
                                            <span class="inlineFlex">
                                                <span class="paddingTop2">
                                                    {{ column.display }}
                                                </span>
                                                <template v-if="column.sortable">
                                                    <template v-if="query.sort === column.src">
                                                        <a v-if="query.order === 'asc'" @click.prevent="toggle(idx)">
                                                            <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                        </a>
                                                        <a v-else @click.prevent="toggle(idx)">
                                                            <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                        </a>
                                                    </template>
                                                    <a v-else @click.prevent="toggle(idx)">
                                                        <i class="fa fa-sort" aria-hidden="true" />
                                                    </a>
                                                </template>
                                            </span>

                                            <span v-if="column.subDisplay" class="inlineFlex marginLeft8">
                                                <span v-if="column.subDisplay" class="paddingTop2">
                                                    {{ column.subDisplay }}
                                                </span>

                                                <template v-if="column.subSortable">
                                                    <template v-if="query.sort === column.subSrc">
                                                        <a v-if="query.order === 'asc'" @click.prevent="toggle(idx, 'subSorting')">
                                                            <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                        </a>
                                                        <a v-else @click.prevent="toggle(idx, 'subSorting')">
                                                            <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                        </a>
                                                    </template>
                                                    <a v-else @click.prevent="toggle(idx, 'subSorting')">
                                                        <i class="fa fa-sort" aria-hidden="true" />
                                                    </a>
                                                </template>
                                            </span>
                                        </th>
                                        <template v-else>
                                            <th
                                                :key="'desktop_slotted' + idx"
                                                :style="[
                                                    column.width !== false
                                                        ? {
                                                              width: column.width,
                                                          }
                                                        : {},
                                                    column.maxWidth !== false
                                                        ? {
                                                              'max-width': column.maxWidth,
                                                          }
                                                        : {},
                                                    column.minWidth !== false
                                                        ? {
                                                              'min-width': column.minWidth,
                                                          }
                                                        : {},
                                                ]"
                                                class="hidden-xs hidden-sm"
                                                scope="col"
                                            >
                                                <slot :name="column.src" />
                                            </th>
                                        </template>
                                        <template v-if="column.mobile">
                                            <th
                                                v-if="!column.slotted"
                                                :key="'mobile_slotted' + idx"
                                                :style="[
                                                    column.mobile_width !== false
                                                        ? {
                                                              width: column.mobile_width,
                                                          }
                                                        : {},
                                                    column.mobile_maxWidth !== false
                                                        ? {
                                                              'max-width': column.mobile_maxWidth,
                                                          }
                                                        : {},
                                                    column.mobile_minWidth !== false
                                                        ? {
                                                              'min-width': column.mobile_minWidth,
                                                          }
                                                        : {},
                                                ]"
                                                class="hidden-md hidden-lg"
                                                scope="col"
                                            >
                                                <span class="inlineFlex">
                                                    <span class="paddingTop2">
                                                        {{ column.display }}
                                                    </span>
                                                    <template v-if="column.sortable">
                                                        <template v-if="query.sort === column.src">
                                                            <a v-if="query.order === 'asc'" @click.prevent="toggle(idx)">
                                                                <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                            </a>
                                                            <a v-else @click.prevent="toggle(idx)">
                                                                <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                            </a>
                                                        </template>
                                                        <a v-else @click.prevent="toggle(idx)">
                                                            <i class="fa fa-sort" aria-hidden="true" />
                                                        </a>
                                                    </template>
                                                </span>

                                                <span v-if="column.subDisplay" class="inlineFlex marginLeft8">
                                                    <span class="paddingTop2">
                                                        {{ column.subDisplay }}
                                                    </span>

                                                    <template v-if="column.subSortable">
                                                        <template v-if="query.sort === column.subSrc">
                                                            <a v-if="query.order === 'asc'" @click.prevent="toggle(idx, 'subSorting')">
                                                                <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                            </a>
                                                            <a v-else @click.prevent="toggle(idx, 'subSorting')">
                                                                <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                            </a>
                                                        </template>
                                                        <a v-else @click.prevent="toggle(idx, 'subSorting')">
                                                            <i class="fa fa-sort" aria-hidden="true" />
                                                        </a>
                                                    </template>
                                                </span>
                                            </th>
                                            <template v-else>
                                                <th
                                                    :key="'mobile_slotted' + idx"
                                                    :style="[
                                                        column.mobile_width !== false
                                                            ? {
                                                                  width: column.mobile_width,
                                                              }
                                                            : {},
                                                        column.mobile_maxWidth !== false
                                                            ? {
                                                                  'max-width': column.mobile_maxWidth,
                                                              }
                                                            : {},
                                                        column.mobile_minWidth !== false
                                                            ? {
                                                                  'min-width': column.mobile_minWidth,
                                                              }
                                                            : {},
                                                    ]"
                                                    class="hidden-md hidden-lg"
                                                    scope="col"
                                                >
                                                    <slot :name="column.src" />
                                                </th>
                                            </template>
                                        </template>
                                    </template>
                                    <th v-if="action_column" class="width20 capitalize" scope="col">{{ $t('global.tables.actionColumnLabel') }}</th>
                                </slot>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="meta.paginator.total == 0 && models.length == 0">
                                <slot
                                    name="ndata"
                                    :model="{
                                        total_column_count: column_count + (action_column ? 1 : 0),
                                    }"
                                >
                                    <tr>
                                        <td :colspan="column_count + (action_column ? 1 : 0)" class="text-center" style="border-top: 0">
                                            {{ $t('global.tables.defaultNoDataLabel') }}
                                        </td>
                                    </tr>
                                </slot>
                            </template>
                            <template v-else>
                                <slot name="adata" :model="models">
                                    <tr v-for="datum in models">
                                        <slot name="cdata" :model="datum">
                                            <template v-for="(column, idx) in cols">
                                                <td
                                                    :class="{
                                                        'hidden-xs hidden-sm': !column.mobile,
                                                    }"
                                                    :data-title="column.display"
                                                    v-text="datum[column.src]"
                                                />
                                            </template>
                                        </slot>
                                        <slot :model="datum" name="actions" />
                                    </tr>
                                </slot>
                            </template>
                        </tbody>
                    </table>

                    <div>
                        <slot name="newData" :model="models" />
                    </div>
                </div>
                <template v-if="meta.paginator.total == 0 && hide_table_if_empty">
                    <slot
                        name="ndata"
                        :model="{
                            total_column_count: column_count + (action_column ? 1 : 0),
                        }"
                    />
                </template>
            </div>
        </slot>
        <slot name="pagination" :model="{ pagination: pagination, meta: meta, from: from, to: to }">
            <div v-if="pagination.length >= 1" class="pagination marginTop20">
                <div class="hidden-xs hidden-sm col-md-6">
                    <ul class="pagination test-listing-pagination">
                        <template v-for="page in pagination">
                            <li
                                v-if="page != '...'"
                                :class="{
                                    active: page == meta.paginator.current_page,
                                }"
                            >
                                <a
                                    @click.prevent="
                                        query.page = page;
                                        debounced_search();
                                    "
                                    >{{ page }}</a
                                >
                            </li>
                            <li v-else>
                                <a @click.prevent="">{{ page }}</a>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="hidden-xs hidden-sm col-md-6">
                    <slot name="bottom-display-from-to" :model="{ meta: meta, from: from, to: to }">
                        <div class="displayNo height100 align-items flexRight">{{ from }} - {{ to }} of {{ meta.paginator.total }}</div>
                    </slot>
                </div>

                <div class="hidden-md hidden-lg col-xs-12">
                    <slot name="bottom-display-from-to" :model="{ meta: meta, from: from, to: to }">
                        <div class="floatRight">{{ from }} - {{ to }} of {{ meta.paginator.total }}</div>
                    </slot>
                </div>
                <div v-if="pagination.length > 1" class="hidden-md hidden-lg col-xs-12">
                    <ul class="pagination test-listing-pagination marginTop20">
                        <template v-for="page in pagination">
                            <li
                                v-if="page != '...'"
                                :class="{
                                    active: page == meta.paginator.current_page,
                                }"
                            >
                                <a
                                    @click.prevent="
                                        query.page = page;
                                        debounced_search();
                                    "
                                    >{{ page }}</a
                                >
                            </li>
                            <li v-else>
                                <a @click.prevent="">{{ page }}</a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </slot>
    </div>
</template>
<script>
import { useAuthUserStore } from '@/stores/auth';
export default {
    props: ['name', 'url', 'columns', 'options'],
    data() {
        return {
            store: useAuthUserStore(),
            internalColumns: [],
            column_count: 0,
            is_tabular: true,
            has_trash_field: false,
            has_search_field: true,
            has_suspended_field: false,
            has_archived_field: false,
            has_at_risk_field: false,
            has_pay_type_field: false,
            has_payment_method_field: false,
            has_activated_field: false,
            has_pagination: true,
            has_thead: true,
            has_thead_title: true,
            has_page_number: true,
            has_drop_page_number: true,
            has_onboarding_field: false,
            has_permission_field: false,
            has_clear_field: false,
            has_top_bar: true,
            hide_table_if_empty: false,
            action_column: true,
            has_table_background: true,
            is_lti3_setting: false,
            has_table_tabs: false,
            cols: {},
            meta: {
                paginator: {
                    count: 0,
                    current_page: 0,
                    last_page: 0,
                    prev_page_url: null,
                    next_page_url: null,
                    limit: 50,
                    total: 0,
                    total_pages: 0,
                    from: 0,
                    to: 0,
                },
            },
            models: {},
            query: {
                page: 1,
                sort: _.get(this.options, 'default_query_fields.sort', 'id'),
                order: _.get(this.options, 'default_query_fields.order', 'desc'),
                per_page: 50,
                q: '',
                isTrashed: '0',
                isSuspended: '0',
                isArchived: '0',
                isAtRisk: '0',
                status: '',
                pageName: '',
                //type:'',
                //paymentMethod:'',
            },
            debounced_search: null,
            delta: 5,
            from: 0,
            to: 0,
            has_table: true,
            has_table_caption: false,
            all: _.get(this.options, 'allCourses', ''),
            removeQuery: false,
        };
    },
    computed: {
        columnNames() {
            return this.internalColumns;
        },
        pagination() {
            var delta = this.delta;
            var current = this.meta.paginator.current_page,
                last = this.meta.paginator.total_pages,
                left = current - delta,
                right = current + delta + 1,
                range = [],
                rangeWithDots = [],
                l = null;

            for (let i = 1; i <= last; i++) {
                if (i == 1 || i == last || (i >= left && i < right)) {
                    range.push(i);
                }
            }
            if (range.length <= 1) {
                return [];
            }
            //return range;
            for (let i of range) {
                if (l) {
                    if (i - l >= 2) {
                        rangeWithDots.push('...');
                    }
                }
                rangeWithDots.push(i);
                l = i;
            }
            return rangeWithDots;
        },
    },
    watch: {
        options: {
            handler: function (after, before) {
                this.initQueryFields();
            },
            deep: true,
        },
        columns: {
            handler(newValue) {
                this.internalColumns = newValue;
                this.initColumn();
            },
            deep: true,
        },
    },
    created() {
        //debounced search
        var search = function (resetPagination = false) {
            if (resetPagination) {
                this.query.page = 1;
            }
            this.query.q = $('#' + this.name + '-id').val() || '';
            this.getList();
        }.bind(this);
        this.debounced_search = _.debounce(search, 300);

        this.initQueryFields();
        if (this.options != undefined) {
            if (this.options.tabular_form != null) {
                this.is_tabular = this.options.tabular_form;
            }
            if (this.options.search_field != null) {
                this.has_search_field = this.options.search_field;
            }
            if (this.options.thead != null) {
                this.has_thead = this.options.thead;
            }
            if (this.options.thead_title != null) {
                this.has_thead_title = this.options.thead_title;
            }
            if (this.options.trash_field != null) {
                this.has_trash_field = this.options.trash_field;
            }
            if (this.options.archived_field != null) {
                this.has_archived_field = this.options.archived_field;
            }
            if (this.options.at_risk_field != null) {
                this.has_at_risk_field = this.options.at_risk_field;
            }
            if (this.options.suspended_field != null) {
                this.has_suspended_field = this.options.suspended_field;
            }
            if (this.options.pay_type_field != null) {
                this.has_pay_type_field = this.options.pay_type_field;
                this.query.type = '';
            }
            if (this.options.payment_method_field != null) {
                this.has_payment_method_field = this.options.payment_method_field;
                this.query.paymentMethod = '';
            }
            if (this.options.activated_field != null) {
                this.has_activated_field = this.options.activated_field;
            }
            if (this.options.onboarding_field != null) {
                this.has_onboarding_field = this.options.onboarding_field;
                this.query.onboarding = '';
            }
            if (this.options.permission_field != null) {
                this.has_permission_field = this.options.permission_field;
                this.query.type = '*';
            }
            if (this.options.pagination != null) {
                this.has_pagination = this.options.pagination;
            }
            if (this.options.action_column != null) {
                this.action_column = this.options.action_column;
            }
            if (this.options.sort != null) {
                this.query.sort = this.options.sort;
            }
            if (this.options.order != null) {
                this.query.order = this.options.order;
            }
            if (this.options.per_page != null) {
                this.query.per_page = this.options.per_page;
            }
            if (this.options.page != null) {
                this.query.page = this.options.page;
            }
            if (this.options.isTrashed != null) {
                this.query.isTrashed = this.options.isTrashed;
            }
            if (this.options.page_number != null) {
                this.has_page_number = this.options.page_number;
            }
            if (this.options.drop_page_number != null) {
                this.has_drop_page_number = this.options.drop_page_number;
            }
            if (this.options.clear_field != null) {
                this.has_clear_field = this.options.clear_field;
            }
            if (this.options.hide_table_if_empty != null) {
                this.hide_table_if_empty = this.options.hide_table_if_empty;
            }
            if (this.options.top_bar != null) {
                this.has_top_bar = this.options.top_bar;
            }
            if (this.options.table_background != null) {
                this.has_table_background = this.options.table_background;
            }
            if (this.options.lti3_setting != null) {
                this.is_lti3_setting = this.options.lti3_setting;
            }
            if (this.options.table_tabs != null) {
                this.has_table_tabs = this.options.table_tabs;
            }
            if (this.options.pageName != null) {
                this.query.pageName = this.options.pageName;
            }
            if (this.options.table != null) {
                this.has_table = this.options.table;
            }
            if (this.options.table_caption != null) {
                this.has_table_caption = this.options.table_caption;
            }
        }
        this.internalColumns = this.columns;
        this.initColumn();
        window.addEventListener('resize', this.handleResize);

        let history = this.store.search_history;
        let search_history_id = _.get(this, 'options.search_history_id', false);
        if (search_history_id) {
            if (history[search_history_id] && !_.isEmpty(history[search_history_id])) {
                var that = this;
                this.$nextTick(function () {
                    _.forIn(history[search_history_id], function (value, key) {
                        if (_.get(that, 'query', {}).hasOwnProperty(key)) {
                            that.query[key] = value;
                        }
                    });
                });
            }
        }
    },
    mounted() {
        //console.log('first get list on mount');
        var that = this;
        this.$nextTick(function () {
            that.getList();
        });
        Events.listen(this.name + '_refresh', () => {
            //console.log('event get list '+this.name);
            this.getList();
        });
        this.handleResize();
        this.$emit('mounted');
    },
    beforeUnmount() {
        Events.off(this.name + '_refresh');
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        clearQueryFields() {
            this.query.isTrashed = '0';
            this.query.q = '';
            this.query.isSuspended = '0';
            this.query.isArchived = '0';
            this.query.isAtRisk = '0';
            this.query.status = '';
            if (this.options.pay_type_field != null) {
                this.query.type = '';
            }
            if (this.options.payment_method_field != null) {
                this.query.paymentMethod = '';
            }
            if (this.options.onboarding_field != null) {
                this.query.onboarding = '';
            }
            if (this.options.permission_field != null) {
                this.query.type = '';
            }
            this.query.page = 1;
            this.query.sort = _.get(this.options, 'default_query_fields.sort', 'id');
            this.query.order = _.get(this.options, 'default_query_fields.order', 'desc');
            this.getList();
        },
        handleResize() {
            if ($(window).width() > 991) {
                this.delta = 5;
            } else {
                this.delta = 1;
            }
        },
        initQueryFields() {
            var query = _.get(this.options, 'query_fields', false);
            var that = this;
            if (query) {
                _.forIn(query, function (value, key) {
                    that.query[key] = value;
                });
            }
            if (this.options.page != null) {
                //if pagination changed from the outside
                if (this.query.page != this.options.page) {
                    this.query.page = this.options.page;
                    this.getList();
                }
            }
        },
        initColumn() {
            this.cols = {};
            //cols
            for (let column in this.internalColumns) {
                this.column_count++;
                this.cols[column] = {};
                //defaults
                this.cols[column]['display'] = column;
                this.cols[column]['src'] = column;
                this.cols[column]['sortable'] = true;
                this.cols[column]['width'] = false;
                this.cols[column]['minWidth'] = false;
                this.cols[column]['maxWidth'] = false;
                this.cols[column]['mobile_width'] = false;
                this.cols[column]['mobile_minWidth'] = false;
                this.cols[column]['mobile_maxWidth'] = false;
                this.cols[column]['mobile'] = true;
                this.cols[column]['subSrc'] = false;
                this.cols[column]['subSortable'] = false;
                this.cols[column]['subDisplay'] = false;
                var a = this.internalColumns[column].split('|');
                //console.log(a);
                for (let a_count in a) {
                    //console.log(a[a_count]);
                    let rules = a[a_count].split(':');
                    //console.log(rules);
                    //display column
                    if (rules.length < 2) {
                        throw 'KRAYAP SEARCH TABLE: all rules require a parameter, none given: ' + rules[0];
                    }
                    if (rules[0] === 'display') {
                        this.cols[column]['display'] = rules[1];
                    } else if (rules[0] === 'subDisplay') {
                        this.cols[column]['subDisplay'] = rules[1];
                    } else if (rules[0] === 'src') {
                        this.cols[column]['src'] = rules[1];
                    } else if (rules[0] === 'subSrc') {
                        this.cols[column]['subSrc'] = rules[1];
                    } else if (rules[0] === 'width') {
                        this.cols[column]['width'] = rules[1];
                    } else if (rules[0] === 'minWidth') {
                        this.cols[column]['minWidth'] = rules[1];
                    } else if (rules[0] === 'maxWidth') {
                        this.cols[column]['maxWidth'] = rules[1];
                    } else if (rules[0] === 'mobile_width') {
                        this.cols[column]['mobile_width'] = rules[1];
                    } else if (rules[0] === 'mobile_minWidth') {
                        this.cols[column]['mobile_minWidth'] = rules[1];
                    } else if (rules[0] === 'mobile_maxWidth') {
                        this.cols[column]['mobile_maxWidth'] = rules[1];
                    } else if (rules[0] === 'sortable') {
                        if (rules[1] == 'false') {
                            this.cols[column]['sortable'] = false;
                        }
                    } else if (rules[0] === 'subSortable') {
                        if (rules[1] == 'true') {
                            this.cols[column]['subSortable'] = true;
                        }
                    } else if (rules[0] === 'mobile') {
                        if (rules[1] == 'hide') {
                            this.cols[column]['mobile'] = false;
                        }
                    } else if (rules[0] === 'slotted') {
                        if (rules[1] == 'true') {
                            this.cols[column]['slotted'] = true;
                        }
                    } else {
                        if (rules[0]) {
                            throw 'KRAYAP SEARCH TABLE: No such rule: ' + rules[0];
                        }
                    }
                }
            }
        },
        toggle(idx, value) {
            if (value == 'subSorting') {
                this.cols[idx].subSrc;
                if (this.query.sort === this.cols[idx].subSrc) {
                    if (this.query.order === 'asc') {
                        this.query.order = 'desc';
                    } else {
                        this.query.order = 'asc';
                    }
                } else {
                    this.query.order = 'asc';
                    this.query.sort = this.cols[idx].subSrc;
                }
            } else {
                this.cols[idx].src;
                if (this.query.sort === this.cols[idx].src) {
                    if (this.query.order === 'asc') {
                        this.query.order = 'desc';
                    } else {
                        this.query.order = 'asc';
                    }
                } else {
                    this.query.order = 'asc';
                    this.query.sort = this.cols[idx].src;
                }
            }
            this.getList();
        },
        data() {
            let data = {};

            for (let property in this.query) {
                data[property] = this.query[property];
            }
            return data;
        },
        getList() {
            this.all = _.get(this.options, 'allCourses', '');
            this.removeQuery = _.get(this.options, 'removeQuery', false);
            var params = '';
            var obj = _.get(this, 'options.params', undefined);
            var newServerMode = _.get(this, 'options.newServerMode', false);

            if (obj != undefined) {
                if (this.options.params != null) {
                    _.forIn(obj, function (value, key) {
                        params = params + '&' + key + '=' + value;
                    });
                }
            }

            if (this.all) {
                var url = this.url.url + '?all&';
            } else {
                var url = this.url.url + '?';
            }

            this.initQueryFields();
            var that = this;

            if (!this.removeQuery) {
                _.forIn(this.query, function (value, key) {
                    if (value instanceof Array) {
                        //url+=key+'='+JSON.stringify(value)+'&';
                        url += key + '=' + value.join() + '&';
                    } else {
                        if (newServerMode && _.get(newServerMode, 'includes', []).includes(key)) {
                            if (key == 'isSuspended' && !that.has_suspended_field) {
                            } else if (key == 'sort') {
                                if (that.query.order == 'desc') {
                                    url += key + '=-' + value + '&';
                                } else {
                                    url += key + '=' + value + '&';
                                }
                            } else if (key == 'order') {
                            } else {
                                url += `filter[${key}]=` + encodeURIComponent(value) + '&';
                            }
                        } else {
                            url += key + '=' + encodeURIComponent(value) + '&';
                        }
                    }
                });
            }
            url = url + params;
            // url = url.replace('%', '');
            console.log(url);
            axios.get(url).then(this.onSuccess.bind(this)).catch(this.onFail.bind(this));
        },
        onSuccess(response) {
            this.models = response.data.data;
            this.setMeta(response.data);
            var ret = {
                meta: this.meta,
                query: this.query,
                data: response,
            };
            this.$emit('retrieveData', ret);
            /*Will fix this soon. Inefficient*/
            let search_history_id = _.get(this, 'options.search_history_id', false);
            if (search_history_id) {
                this.store.updateSearchHistory({
                    id: search_history_id,
                    ...this.query,
                });
            }
            if (this.meta.pagination.total_pages == 0) {
                this.query.page == 0;
            } else if (this.meta.paginator.total_pages < this.meta.paginator.current_page) {
                this.query.page = this.meta.paginator.total_pages;
                this.getList();
            }
        },
        onFail(response) {},
        setMeta(data) {
            var that = this;
            if (_.get(data, 'meta', false)) {
                //kr api
                this.meta.paginator.total = data.meta.total;
                this.meta.paginator.count = data.meta.to - data.meta.from;
                this.meta.paginator.limit = data.meta.per_page;
                this.meta.paginator.current_page = data.meta.current_page;
                this.meta.paginator.total_pages = data.meta.last_page;
                this.meta.paginator.prev_page_url = _.get(data, 'links.prev', null);
                this.meta.paginator.next_page_url = _.get(data, 'links.next', null);
                this.from = data.meta.total == 0 ? 0 : data.meta.from;
                this.to = data.meta.total == 0 ? 0 : data.meta.to;
                _.forOwn(data.meta, function (value, key) {
                    if (key != 'pagination') {
                        that.meta[key] = value;
                    }
                });
            }
        },
    },
};
</script>
<style scoped lang="scss">
.topBar {
    margin-bottom: 20px;
}

.topBar .col-xs-12,
.topBar .col-md-4 {
    padding: 0;
}

.subBar {
    margin-bottom: 20px;
}

.subBar > .viewFilter {
    display: flex;
    align-items: center;
    width: auto;
}

.pagination {
    display: flex;
    flex-wrap: wrap;
}

div.displayNo {
    text-align: right;
}
.displayNo {
    text-align: right;
}

.table .table {
    margin-bottom: 0;
}

.table .table td {
    padding: 15px;
}

.table .table td.padding-0 {
    padding: 0;
}

.cardsCon .topBar,
.cardsCon .subBar {
    padding-left: 20px;
}
.md-upper ~ .md-lower {
    justify-content: flex-end;
}

@media (min-width: 992px) {
    .topBar {
        display: flex;
        justify-content: space-between;
    }

    .subBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .subBar .displayNo {
        margin-left: 20px;
    }

    .searchBox {
        min-width: 145px;
        width: auto;
        max-width: 200px;
    }
}
@media (max-width: 991px) {
    .topBar {
        margin-bottom: 20px;
    }

    .md-upper,
    .md-lower {
        width: 100%;
    }

    .md-upper {
        margin-bottom: 20px;
    }

    .md-lower .form-dropdown {
        min-width: 100px !important;
    }

    .subBar > div {
        width: 100%;
    }
    .subBar > div:first-child {
        margin-bottom: 20px;
    }

    .subBar .displayNo {
        margin-left: 0;
    }
}

.table {
    max-width: inherit;
}

.md-upper {
    display: flex;
    align-items: center;
    font-size: 0.889rem;
    font-weight: bold;
    width: auto;
    line-height: 1;
    border: 1px solid none;
}

.upper-right {
    margin-left: 20px;
    &:empty {
        margin-left: 0;
    }
}

.navigation-tab {
    margin-bottom: 20px;
    .links {
        margin-bottom: 0;
    }
}

.x_with_side_bar {
    .md-lower {
        width: 100%;
        justify-content: space-between;
    }
}
</style>
